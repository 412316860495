import { useCallback } from "react";

import { IDialogInfo } from "@appTypes/dialogs";
import { OK as HTTP_OK } from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { IAxiosResponseWithError, network } from "@utils/network/network";

export enum CHECK_SRS_RESULTS {
  ACTIVE = "ACTIVE",
  UNVERIFIED_SRS = "UNVERIFIED_SRS",
  UNVERIFIED_DEFAULT_RISK_SCORE = "UNVERIFIED_DEFAULT_RISK_SCORE",
}

export enum CHECK_SRS_DIALOG_TYPES {
  SRS_CHECK_HAVE_NOT_PASSED = "SRS_CHECK_HAVE_NOT_PASSED",
  SRS_CHECK_FAILED = "SRS_CHECK_FAILED",
  SRS_CHECK_FINALLY_FAILED = "SRS_CHECK_FINALLY_FAILED",
}

export interface ICheckSrsResponse {
  status: CHECK_SRS_RESULTS;
}
export interface ISRSCheckDialogInfo extends IDialogInfo<CHECK_SRS_DIALOG_TYPES, unknown> {
  isSuccess: boolean;
}

type useCheckSRSReturnType = {
  checkSRS: () => Promise<IAxiosResponseWithError<ICheckSrsResponse>>;
  getDialogInfoViaSrsCheck: (isRetry?: boolean) => Promise<ISRSCheckDialogInfo>;
  performSrsCheck: (isRetry?: boolean) => Promise<IDialogInfo<CHECK_SRS_DIALOG_TYPES, never> & { isSuccess: boolean }>;
};

export const getDialogBySrsCheckResponse = (response: ICheckSrsResponse): ISRSCheckDialogInfo => {
  switch (response.status) {
  case CHECK_SRS_RESULTS.UNVERIFIED_DEFAULT_RISK_SCORE:
  case CHECK_SRS_RESULTS.UNVERIFIED_SRS: {
    return {
      isSuccess: false,
      dialog: dialogContents.srsCheckHaveNotPassed,
      dialogType: CHECK_SRS_DIALOG_TYPES.SRS_CHECK_HAVE_NOT_PASSED,
    };
  }
  case CHECK_SRS_RESULTS.ACTIVE:
  default: {
    return { isSuccess: true };
  }
  }
};
// TODO: Possibly need to update request with different responses for 1st and 2nd errors. Implemented on back-end
// This flow have different behavior on Onboarding
export const useCheckSRS = (): useCheckSRSReturnType => {
  const checkSRS = (): Promise<IAxiosResponseWithError<ICheckSrsResponse>> => {
    return network.request<ICheckSrsResponse>("connect", "customer/risk-score", {
      method: "POST",
      data: {},
      params: {},
    });
  };

  const getDialogInfoViaSrsCheck = async (isRetry?: boolean): Promise<ISRSCheckDialogInfo> => {
    const { data, status } = await checkSRS();
    if (status === HTTP_OK) {
      return getDialogBySrsCheckResponse(data);
    } else {
      return isRetry
        ? {
          isSuccess: false,
          dialog: dialogContents.srsCheckFinallyFailed,
          dialogType: CHECK_SRS_DIALOG_TYPES.SRS_CHECK_FINALLY_FAILED,
        }
        : {
          isSuccess: false,
          dialog: dialogContents.srsCheckFailed,
          dialogType: CHECK_SRS_DIALOG_TYPES.SRS_CHECK_FAILED,
        };
    }
  };

  const performSrsCheck = useCallback(
    async (isRetry?: boolean): Promise<IDialogInfo<CHECK_SRS_DIALOG_TYPES, never> & { isSuccess: boolean }> => {
      const { isSuccess, dialog, dialogType } = await getDialogInfoViaSrsCheck(isRetry);

      return { isSuccess, dialog, dialogType };
    },
    [getDialogInfoViaSrsCheck],
  );

  return { checkSRS, getDialogInfoViaSrsCheck, performSrsCheck };
};
