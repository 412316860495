import { StepIconProps } from "@mui/material/StepIcon";
import { styled } from "@mui/material/styles";
import { FC } from "react";

import { ReactComponent as Checkmark } from "../../../assets/icons/checkmark_stepper.svg";

export const StepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: theme.palette.secondary.navy,
  }),
  "& .StepIcon-active": {
    width: 24,
    height: 24,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.navy,
    border: `2px solid ${theme.palette.secondary.navy}`,
    [theme.breakpoints.down("sm")]: {
      width: 16,
      height: 16,
    },
  },
  "& .StepIcon-circle": {
    width: 24,
    height: 24,
    borderRadius: "50%",
    border: `2px solid currentColor`,
    backgroundColor: ownerState.active ? theme.palette.greyscale.white : "#ECEFF9",
    [theme.breakpoints.down("sm")]: {
      width: 16,
      height: 16,
    },
  },
}));

export const StepIcon: FC<StepIconProps> = ({ active, completed, className }) => (
  <StepIconRoot ownerState={{ active }} className={className}>
    {completed ? (
      <div className="StepIcon-active">
        <Checkmark />
      </div>
    ) : (
      <div className="StepIcon-circle" />
    )}
  </StepIconRoot>
);
