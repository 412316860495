import { Box, Grid, styled } from "@mui/material";
import { Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SandClock } from "@assets/uiImages/smth_wrong.svg";

const ContentContainer = styled(Grid)(({ theme }) => ({
  height: "100%",
  width: "100%",
  paddingRight: "0px",
  paddingLeft: "0px",
  justifyContent: "center",
  [theme.breakpoints.up("sm")]: {
    paddingTop: "24px",
    paddingRight: "64px",
    paddingLeft: "64px",
  },
}));

const ItemContainer = styled(Box)(({ theme }) => ({
  padding: "24px",
  borderRadius: "16px",
  // background: "white",
  boxSizing: "border-box",
  minHeight: "340px",
  [theme.breakpoints.up("sm")]: {
    padding: "40px",
  },
}));

export const UnderMaintenance: FC = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
      <ContentContainer maxWidth="1440px" container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display={"flex"}
          justifyContent="center"
          paddingLeft="0px"
        >
          <ItemContainer width="100%" maxWidth="872px" style={{ background: "white" }}>
            <Grid
              container
              flexDirection="column"
              px={16}
              margin="auto"
              alignItems="center"
              mb="36px"
            >
              <Grid item mb="40px">
                {<SandClock />}
              </Grid>
              <Grid item mb={3}>
                <Typography
                  variant="h1"
                  fontSize={35}
                  fontWeight="bold"
                  textAlign="center"
                >
                  {t("checkout.title.under_maintenance_title")}
                </Typography>
              </Grid>
              <Grid item mb="16px">
                <Typography
                  variant="h5"
                  fontSize={20}
                  fontWeight="regular"
                  textAlign="center"
                >
                  {t("checkout.title.under_maintenance")}
                </Typography>
              </Grid>
            </Grid>
          </ItemContainer>
        </Grid>
      </ContentContainer>
    </Box>
  );
};
