import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_ARAB } from "./arab/translation";
import { WEB_CHECKOUT_AR } from "./arab/webCheckout";
import { TRANSLATIONS_EN } from "./en/translation";
import { WEB_CHECKOUT_EN } from "./en/webCheckout";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          ...TRANSLATIONS_EN,
          checkout: WEB_CHECKOUT_EN,
        },
      },
      ar: {
        translation: {
          ...TRANSLATIONS_ARAB,
          checkout: WEB_CHECKOUT_AR,
        },
      },
    },
    detection: {
      order: ["querystring", "localStorage"],
      lookupQuerystring: "lang",
    },
  });
