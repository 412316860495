import { useContext } from "react";

import { FeaturesContext } from "./Features.provider";

interface IFeatureUtils {
  isActiveFeature(name: string): boolean;
}

export const useFeatures = (): IFeatureUtils => {
  const features = useContext(FeaturesContext);
  const isActiveFeature = (featureName: string) => features.activeFeatures.includes(featureName);

  return { isActiveFeature };
};
