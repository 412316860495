import { useEffect, useState } from "react";

import { getContractKeyRequest } from "../network/getContractKey";

export const useContractKey = (): { contractKey: string; isContractKeyError: boolean } => {
  const [contractKey, setContractKey] = useState("");
  const [isContractKeyError, setContractKeyEror] = useState(false);

  useEffect(() => {
    const getContractKey = async () => {
      try {
        const { data } = await getContractKeyRequest();

        setContractKey(data.key);
      } catch (e) {
        setContractKeyEror(true);
        console.log("Error fetching", e);
      }
    };

    getContractKey();
  }, []);

  return { contractKey, isContractKeyError };
};
