import { Typography } from "@ui-kit";
import { Trans } from "react-i18next";

import { CountdownTimer } from "@components/CountdownTimer";
import { fontVariants } from "@constants/types";
import { ITimeObject } from "@utils/services/Timer/timer.types";

interface ITextWithCountdownTimer {
  countdownTimer: ITimeObject<number>;
  textKey: string;
  onTimeoutCallback?: () => Promise<void> | void;
  typographyVariant?: fontVariants;
}

export const TextWithCountdownTimer: React.FC<ITextWithCountdownTimer> = ({
  countdownTimer,
  textKey,
  onTimeoutCallback,
  typographyVariant,
}) => {
  return (
    <Typography variant={typographyVariant} textAlign="center" component="span">
      <Trans
        i18nKey={textKey}
        defaults={textKey}
        values={{ timer: countdownTimer }}
        components={[
          <CountdownTimer
            key={0}
            hours={countdownTimer.hours}
            minutes={countdownTimer.minutes}
            seconds={countdownTimer.seconds}
            onTimeoutCallback={onTimeoutCallback}
          />,
        ]}
      />
    </Typography>
  );
};
