import { ITimeObject } from "./timer.types";

export const COUNTDOWN_TIMES = {
  ["10sec"]: 10 * 1000,
  ["1min"]: 60 * 1000,
  ["2min"]: 120 * 1000,
  ["5min"]: 5 * 60 * 1000,
  ["10min"]: 10 * 60 * 1000,
  ["15min"]: 15 * 60 * 1000,
  ["30min"]: 30 * 60 * 1000,
  ["24h"]: 24 * 60 * 60 * 1000,
  ["36h"]: 36 * 60 * 60 * 1000,
};

export const convertSecondsToMinAndSec = (secondsToConvert: number): ITimeObject<string> => {
  const hours = Math.floor(secondsToConvert / 3600);
  const minutes = Math.floor((secondsToConvert % 3600) / 60);
  const seconds = secondsToConvert % 60;

  return {
    minutes: minutes?.toString()?.length === 1 ? `0${minutes}` : `${minutes}`,
    seconds: seconds?.toString()?.length === 1 ? `0${seconds}` : `${seconds}`,
    hours: hours?.toString()?.length === 1 ? `0${hours}` : `${hours}`,
  };
};

export const convertMilliseconds = (msCount: number): ITimeObject<number> | null => {
  if (msCount > 0) {
    const hours = Math.floor(msCount / (1000 * 60 * 60));
    const minutes = Math.floor(msCount / 60000) % 60;
    const seconds = Number(((msCount % 60000) / 1000).toFixed(0));

    return { minutes, seconds, hours };
  } else {
    return null;
  }
};

export const getTimeRemaining = (startDate: Date, countdown: number, basedOnUtc = true): ITimeObject<number> | null => {
  if (isNaN(startDate.getTime())) {
    return null;
  }

  const currentDate = new Date();
  const convertedCurrentTime = basedOnUtc
    ? new Date(currentDate.getTime() + currentDate.getTimezoneOffset() * 60000)
    : currentDate;

  const diff = countdown - (convertedCurrentTime.getTime() - startDate.getTime());

  // 1420 fix
  return convertMilliseconds(diff);
};

export const getTimeBefore = (date: string): ITimeObject<number> | null => {
  const futureDate = new Date(date).getTime();
  const currentDate = new Date();

  if (isNaN(futureDate)) {
    return null;
  }

  const diff = futureDate - currentDate.getTime();

  return convertMilliseconds(diff);
};

export const convertTimeWithUTC = (date: Date): Date => {
  return new Date(new Date(date.getTime() + date.getTimezoneOffset() * 60000));
};

export const getTimeDiffInSeconds = (time1: Date, time2: Date): number => {
  return Math.round((time1.getTime() - time2.getTime()) / 1000);
};
