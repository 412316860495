import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  RadioGroup,
  styled,
  useRadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";

import { COLORS } from "../../constants/enums/COLORS";
import { CustomRadio } from "../CustomRadio";
import { Typography } from "../Typography";

interface IRadioButtonLabel {
  label: string;
  currency: string;
  cost: string;
  units: string;
}

interface IRadioButtonProps extends IRadioButtonLabel {
  value: string;
  disabled?: boolean;
  key?: string;
}

interface IRadioInputsGroupProps {
  value: string;
  onChange: (value: string) => void;
  formControlsArray?: IRadioButtonProps[];
  radioGroupDefaultValue?: string;
  radioGroupAriaLabel?: string;
  justifyContent?: string;
  rowSpacing?: number;
}

const StyledFormLabel = styled(FormControlLabel)(({ theme, checked, disabled }) => ({
  border: checked ? `2px solid ${theme.palette?.secondary?.main}` : `1px solid ${theme.palette?.greyscale?.grey08}`,
  opacity: disabled ? "0.8" : "1",
  backgroundColor: disabled ? "#FAFBFB" : "",
  boxShadow: checked ? `0px 4px 14px rgba(0, 0, 0, 0.12)` : "",
  borderRadius: "10px",
  padding: "11px 14px",
  width: "267px",
  display: "flex",
  "& > span:nth-child(2)": {
    display: "flex",
    width: "100%",
  },
}));

const LabelContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
}));

function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormLabel checked={checked} {...props} />;
}

const CustomLabel: FC<IRadioButtonLabel> = ({ label, currency, cost, units }) => (
  <LabelContainer>
    <Typography variant="p2" fontWeight="bold">
      {label}
    </Typography>
    <div>
      <Typography variant="p3">
        {currency} {cost}/
      </Typography>
      <Typography variant="p3" color={COLORS.GREY_64}>
        {units}
      </Typography>
    </div>
  </LabelContainer>
);

export const RadioButtonsGroup: React.FC<IRadioInputsGroupProps> = ({
  onChange,
  value,
  formControlsArray,
  radioGroupAriaLabel,
  radioGroupDefaultValue = "",
  justifyContent = "space-between",
  rowSpacing = 3,
}) => {
  return (
    <FormControl component="fieldset" fullWidth>
      <RadioGroup
        sx={{ marginLeft: "2px" }}
        defaultValue={radioGroupDefaultValue}
        {...(radioGroupAriaLabel && { ["aria-label"]: radioGroupAriaLabel })}
        onChange={(event, value) => onChange(value)}
        value={value || radioGroupDefaultValue}
      >
        <Grid
          container
          rowSpacing={rowSpacing}
          display="flex"
          justifyContent={justifyContent}
        >
          {!!formControlsArray?.length &&
            formControlsArray.map(({ value, cost, currency, units, label, key, disabled = false }) => (
              <Grid
                item
                flexBasis="auto"
                key={key || label}
                xs={12}
                sm={12}
                md={6}
              >
                <MyFormControlLabel
                  value={value}
                  control={<CustomRadio />}
                  label={<CustomLabel
                    label={label}
                    cost={cost}
                    currency={currency}
                    units={units}
                  />}
                  disabled={disabled}
                />
              </Grid>
            ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};
