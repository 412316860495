import { COMMON_REQUEST_DIALOGS, IDialogInfo } from "@appTypes/dialogs";
import { IKYCVerificationResponse, KYC_VALIDATION_STATUSES } from "@appTypes/responses";
import { OK as HTTP_OK } from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { IAxiosResponseWithError, network } from "@utils/network/network";

import { somethingWentWrongDialog } from "../SetKYC.constants";
import { KYC_VERIFICATION_DIALOGS } from "../SetKYC.types";

export interface IRequestResult
  extends IDialogInfo<
    KYC_VERIFICATION_DIALOGS | COMMON_REQUEST_DIALOGS,
    {
      blockedUntil?: string;
    }
  > {
  isSuccess: boolean;
}

type useVerifyKYCReturnType = { verifyKYCCode: (otpCode: string) => Promise<IRequestResult> };

export const useVerifyKYCCode = (): useVerifyKYCReturnType => {
  const verifyOTPCodeRequest = (otpCode: string): Promise<IAxiosResponseWithError<IKYCVerificationResponse>> => {
    return network.request<IKYCVerificationResponse>("connect", "kyc/otp/verification", {
      method: "POST",
      withCredentials: process.env.REACT_APP_WITH_MOCK_SERVER !== "true",
      data: {
        otpCode,
      },
    });
  };

  const verifyKYCCode = async (otpCode: string): Promise<IRequestResult> => {
    const { data, status } = await verifyOTPCodeRequest(otpCode);

    if (status === HTTP_OK) {
      switch (data.result) {
      case KYC_VALIDATION_STATUSES.SUCCESS:
        return { isSuccess: true };

      case KYC_VALIDATION_STATUSES.FAILURE:
        return {
          isSuccess: false,
          dialogType: KYC_VERIFICATION_DIALOGS.OTP_FAILED,
        };

      case KYC_VALIDATION_STATUSES.TEMP_BLOCKED:
        return {
          isSuccess: false,
          dialogType: KYC_VERIFICATION_DIALOGS.USER_TEMP_BLOCKED,
          dialog: dialogContents.userLockedByRegAttempts,
          dialogOptions: {
            blockedUntil: data.blockedUntil,
          },
        };

      default:
        return somethingWentWrongDialog;
      }
    }

    return somethingWentWrongDialog;
  };

  return { verifyKYCCode };
};
