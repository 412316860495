import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";

import { IDialogWithHandler } from "@appTypes/dialogs";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { siteMap } from "@constants/siteMap";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";
import { getTimeBefore } from "@utils/services/Timer/Timer.service";
import { ITimeObject } from "@utils/services/Timer/timer.types";

import { UpdateDOBDialogTypes, DIALOG_TYPES } from "../UpdateDateOfBirth.types";
import { DialogContentsSwitcher } from "./DialogContentSwitcher";

interface IUpdateDOBDialog
  extends IDialogWithHandler<UpdateDOBDialogTypes, { blockedUntil?: string; attempts?: number }> {
  isDialogOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

export const UpdateDOBDialog: FC<IUpdateDOBDialog> = memo(
  ({ dialog, dialogType, dialogOptions, isDialogOpen, closeDialog, lastAction = noop, parameters = [] }) => {
    const { t } = useTranslation();

    const [blockingTimer, setBlockingTimer] = useState<ITimeObject<number> | null>(null);

    useEffect(() => {
      if (dialogType === DIALOG_TYPES.TEMP_BLOCKED && dialogOptions?.blockedUntil) {
        setBlockingTimer(getTimeBefore((dialogOptions?.blockedUntil as string) || ""));
      }
    }, [dialogOptions, dialogType]);

    const handleAgreeDialogClick = useCallback(() => {
      switch (dialogType) {
      case DIALOG_TYPES.TEMP_BLOCKED:
        logoutWithRedirect(siteMap.LogInPage.path);
        break;
      default:
        closeDialog();
        lastAction(...parameters);
        break;
      }
    }, [dialogType, lastAction]);

    const extendedDialogOptions = useMemo(() => {
      return {
        blockingTimer,
        closeDialog,
      };
    }, [blockingTimer]);

    return (
      <>
        <BasicDialog
          open={isDialogOpen}
          onClose={DIALOG_TYPES.SOMETHING_WENT_WRONG ? closeDialog : noop}
          onAgree={handleAgreeDialogClick}
          title={t(dialog?.title as string)}
          description={
            <DialogContentsSwitcher
              dialogType={dialogType}
              description={dialog?.description}
              dialogOptions={extendedDialogOptions}
            />
          }
          icon={dialog?.icon}
          cancelButtonText={dialog?.cancelText ? t(dialog.cancelText as string) : ""}
          agreeButtonText={dialog?.agreeText ? t(dialog.agreeText as string) : ""}
        />
      </>
    );
  },
);

UpdateDOBDialog.displayName = "UpdateDOBDialog";
