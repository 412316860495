import React, { createContext } from "react";

import { featureService } from "./Features.service";
import { IFeature } from "./Features.types";

interface IFeatureContext {
  features: IFeature[];
  activeFeatures: string[];
}

export const FeaturesContext = createContext<IFeatureContext>({ features: [], activeFeatures: [] });

interface IAuthProvider {
  children: React.ReactNode;
}

export const FeaturesProvider: React.FC<IAuthProvider> = ({ children }) => {
  return <FeaturesContext.Provider value={featureService}>{children}</FeaturesContext.Provider>;
};
