import { useEffect } from "react";

import { locationService } from "./location";

export const useLoadingFlag = (setLoadingFlag: (loadingFlag: boolean) => void): void => {
  useEffect(() => {
    const locationSubscription = locationService
      .getLoadingFlag()
      .subscribe((loadingFlag: boolean) => setLoadingFlag(loadingFlag));

    return () => locationSubscription.unsubscribe();
  }, []);
};
