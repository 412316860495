import { AxiosRequestConfig } from "axios";

import { CLIENT_CONNECT, CLIENT_FRONTEND, UNPROTECTED_ENDPOINTS } from "@constants/api";
import { authorizationStore } from "@store/authorization";
import { IAxiosResponseWithError, network } from "@utils/network/network";

type useRequestType = [logoutUserRequest: () => Promise<IAxiosResponseWithError<void>>];

const logoutConfig = (refreshToken: string, accessToken: string, clientId: string): Omit<AxiosRequestConfig, "url"> => {
  return {
    method: "POST",
    headers: { "Content-type": "application/json", Authorization: `Bearer ${accessToken}` },
    data: { client_id: clientId, refresh_token: refreshToken },
  };
};

export const useLogout = (): useRequestType => {
  const logoutUserRequest = async (): Promise<IAxiosResponseWithError<void>> => {
    const { refresh_token, access_token } = authorizationStore.getValue();
    const { lastRequest } = authorizationStore.getLoggedInStatusValue();
    const clientId = UNPROTECTED_ENDPOINTS.includes(lastRequest) ? CLIENT_FRONTEND : CLIENT_CONNECT;

    return network.request<void>("connect", "customer/logout", logoutConfig(refresh_token, access_token, clientId));
  };

  return [logoutUserRequest];
};
