import { ORDER_STATUSES } from "@constants/business/orderStatuses";

import { webCheckoutPageMap } from "./pageMap";

export const orderStatusMap: Partial<Record<keyof typeof ORDER_STATUSES, string>> = {
  [ORDER_STATUSES.WAITING_CONFIRMATION_CALL]: webCheckoutPageMap.WaitingConfirmation.path,
  [ORDER_STATUSES.CANCELED_BY_LMS]: webCheckoutPageMap.DidntMeetQuaraCriteria.path,
  [ORDER_STATUSES.DEFAULTED]: webCheckoutPageMap.DidntMeetQuaraCriteria.path,
  [ORDER_STATUSES.WAITING_FOR_SIGNING]: webCheckoutPageMap.SignContract.path,
  [ORDER_STATUSES.DECLINED]: webCheckoutPageMap.DeclinedByUser.path,
  [ORDER_STATUSES.DECLINED_ON_WEB]: webCheckoutPageMap.DeclinedByUser.path,
  [ORDER_STATUSES.EXPIRED_NO_SIGNED_CONTRACT]: webCheckoutPageMap.ContractExpired.path,
  [ORDER_STATUSES.COMPLETED]: webCheckoutPageMap.Completed.path,
  [ORDER_STATUSES.CANCELLED_FROM_MERCHANT]: webCheckoutPageMap.CanceledFromMerchant.path,
  [ORDER_STATUSES.REJECTED_BY_PHONE_CALL]: webCheckoutPageMap.CanceledFromMerchant.path,
  [ORDER_STATUSES.LATE]: webCheckoutPageMap.Late.path,
};
