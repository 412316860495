import { FormControl, FormHelperText, MenuItem, Select, SelectProps, styled } from "@mui/material";

import { ReactComponent as ChevronRight } from "../../assets/icons/chevron_right.svg";
import { COLORS } from "../../constants/enums/COLORS";
import { StyledLabel } from "../Inputs/inputsStyles";

interface ISelectInputProps extends SelectProps {
  showAdditionalMessage?: boolean;
  hintText?: string;
  disabled?: boolean;
  values?: string[];
}

const StyledSelect = styled(Select)(({ theme }) => ({
  background: theme.palette?.greyscale?.grey04 || COLORS.GREY_04,
  borderRadius: "8px",
  color: theme.palette?.primary?.dark || COLORS.PRIMARY_DARK,
  fontWeight: 500,
  height: "56px",
  fontSize: "1.4rem",
  padding: "16px",
  minWidth: "327px",
  textAlign: "left",
  "& .MuiSelect-select:focus": {
    backgroundColor: theme.palette?.greyscale?.grey04 || COLORS.GREY_04,
  },
  "&.MuiInput-root": {
    marginTop: "0",
    "& .MuiSelect-iconOpen": {
      transform: "rotate(90deg)",
    },
  },
}));

const StyledChevron = styled(ChevronRight)(({ theme }) => ({
  "&.MuiSelect-icon": {
    marginRight: "8px",
    fontSize: "20px",
    "& path": {
      stroke: theme.palette?.secondary?.main || COLORS.SECONDARY_MAIN,
    },
  },
}));

export const CustomSelect: React.FC<ISelectInputProps> = ({
  label,
  labelId,
  values,
  disabled,
  showAdditionalMessage = true,
  hintText,
  ...props
}) => {
  return (
    <FormControl variant={"standard"} disabled={disabled}>
      <StyledLabel shrink={true} disableAnimation={true} htmlFor={labelId}>
        {label}
      </StyledLabel>
      <StyledSelect IconComponent={(props) => <StyledChevron {...props} />} labelId={labelId} {...props}>
        {!!values?.length &&
          values.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
      </StyledSelect>
      {showAdditionalMessage && <FormHelperText>{hintText || ` `}</FormHelperText>}
    </FormControl>
  );
};

export default CustomSelect;
