import { validateNationalId } from "@modules/Registration/SetUserData/SetUserData.validators";
import { composeValidators, emptyValueValidator } from "@utils/services/Validation";

import { FORM_FIELDS, NATIONAL_ID_NOT_VALID_ERROR } from "./login.constants";

const isPasswordValid = emptyValueValidator(NATIONAL_ID_NOT_VALID_ERROR);
const validatePassword = composeValidators(isPasswordValid);

export const loginInfoValidators = {
  [FORM_FIELDS.NATIONAL_ID]: validateNationalId,
  [FORM_FIELDS.PASSWORD]: validatePassword,
};
