import { UserDataFormFields } from "@appTypes/userData";

export enum USER_FORM_FIELDS {
  BIRTH_DATE = "BIRTH_DATE",
  EMAIL = "EMAIL",
  MOBILE_PHONE = "MOBILE_PHONE",
  NATIONAL_ID = "NATIONAL_ID",
}

export const DEFAULT_USER_FORM_DATA: UserDataFormFields = {
  [USER_FORM_FIELDS.BIRTH_DATE]: "",
  [USER_FORM_FIELDS.EMAIL]: "",
  [USER_FORM_FIELDS.MOBILE_PHONE]: "",
  [USER_FORM_FIELDS.NATIONAL_ID]: "",
};
