import { styled } from "@mui/material";
import Box from "@mui/material/Box/Box";
import { FC } from "react";

import { COLORS } from "../../../constants/enums/COLORS";
import { Banner } from "../../Banner";
import { Typography } from "../../Typography/Typography";

interface ICreditLimitItemStyles {
  isChosen?: boolean;
  disabled?: boolean;
}

const ContainerWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "isChosen",
})<ICreditLimitItemStyles>(({ theme, isChosen }) => ({
  "&:after": {
    position: "relative",
    display: "block",
    content: isChosen && "''",
    width: "1px",
    margin: "auto",
    height: 0,
    borderLeft: "12px solid transparent",
    borderRight: "12px solid transparent",
    borderTop: `8px solid ${theme.palette.secondary.navy}`,
  },
}));

const CreditLimitItemContainer = styled(Box, {
  shouldForwardProp: (prop) => !["isChosen", "disabled"].includes(prop as string),
})<ICreditLimitItemStyles>(({ theme, isChosen, disabled }) => ({
  cursor: disabled ? "" : "pointer",
  display: "flex",
  flexDirection: "column",
  width: "170px",
  height: "79px",
  border: `1px solid ${isChosen ? theme.palette.secondary.navy : theme.palette.greyscale.grey08}`,
  background: isChosen ? theme.palette.secondary.navy : disabled ? COLORS.GREY_02 : "white",
  color: isChosen ? "white" : "black",
  padding: "12px",
  opacity: disabled ? "0.8" : "1",
}));

const LabelTypography = styled(Typography)(({ theme }) => ({
  lineHeight: theme.direction === "rtl" ? "1.2rem" : "",
}));

interface ICreditLimitItemProps extends ICreditLimitItemStyles {
  isChosen?: boolean;
  disabled?: boolean;
  label: string;
  bannerLabel?: string;
  header: JSX.Element;
  id: string;
  onClick(itemId: string): void;
}

export const CreditLimitItem: FC<ICreditLimitItemProps> = ({
  isChosen = false,
  disabled = false,
  id,
  onClick,
  header,
  label,
  bannerLabel,
}) => {
  const lang = localStorage.getItem("i18nextLng");

  return (
    <ContainerWrapper isChosen={isChosen}>
      <CreditLimitItemContainer
        isChosen={isChosen}
        disabled={disabled}
        onClick={disabled ? () => undefined : () => onClick(id)}
      >
        <Box>{header}</Box>
        <Box mt="12px">
          <LabelTypography variant="c1" color={isChosen ? "white" : COLORS.GREY_64}>
            {label}
          </LabelTypography>
        </Box>
        {bannerLabel && (
          <Banner
            label={bannerLabel as string}
            lang={lang as string}
            marginForArabic={12}
            marginForEn={12}
          />
        )}
      </CreditLimitItemContainer>
    </ContainerWrapper>
  );
};
