import { Radio, RadioProps, styled } from "@mui/material";

import { COLORS } from "../../constants/enums/COLORS";

const RadioIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  boxShadow: `inset 0px 0px 0px 2px ${theme.palette.greyscale.grey32 || COLORS.GREY_32}`,
  backgroundColor: theme.palette.greyscale.white || COLORS.WHITE,
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "input:disabled ~ &": {
    boxShadow: `inset 0px 0px 0px 2px ${theme.palette.greyscale.grey08 || COLORS.GREY_08}`,
  },
}));

const RadioCheckedIcon = styled(RadioIcon)(({ theme }) => ({
  boxShadow: `inset 0px 0px 0px 6px ${theme.palette.secondary.main || COLORS.SECONDARY_MAIN}`,
}));

export const CustomRadio: React.FC<RadioProps> = (props) => {
  return <Radio icon={<RadioIcon />} checkedIcon={<RadioCheckedIcon />} {...props} />;
};
