import { USER_FORM_FIELDS } from "@constants/defaults/userData";
import {
  composeValidators,
  containsOnlyNumbersValidator,
  emailValidator,
  lengthValidator,
  luhnValidator,
  minValueValidator,
  phoneValidator,
  startsWithValidator,
} from "@utils/services/Validation";

import { FORM_ERRORS } from "./SetUserData.constants";

const isAgeCorrect = minValueValidator(FORM_ERRORS.BIRTH_DATE_UNDERAGE_ERROR)(21);
const validateBirthDate = composeValidators(isAgeCorrect);

const isEmailValid = emailValidator(FORM_ERRORS.EMAIL_NOT_VALID_ERROR);
const validateEmail = composeValidators(isEmailValid);

const isPhoneValid = phoneValidator(FORM_ERRORS.PHONE_NOT_VALID_ERROR);
const validatePhone = composeValidators(isPhoneValid);

const isNationalIdValid = luhnValidator(FORM_ERRORS.NATIONAL_ID_NOT_VALID_ERROR);
const isNationalIdLengthValid = lengthValidator(FORM_ERRORS.NATIONAL_ID_NOT_VALID_ERROR)(10);
const isNationalIdCharsValid = containsOnlyNumbersValidator(FORM_ERRORS.NATIONAL_ID_NOT_VALID_ERROR);
const isNationalIdStartWithCharsValid = startsWithValidator(FORM_ERRORS.NATIONAL_ID_NOT_VALID_ERROR)(["1", "2"]);

export const validateNationalId = composeValidators(
  isNationalIdValid,
  isNationalIdLengthValid,
  isNationalIdStartWithCharsValid,
  isNationalIdCharsValid,
);

export const registrationInfoValidators = {
  [USER_FORM_FIELDS.BIRTH_DATE]: validateBirthDate,
  [USER_FORM_FIELDS.EMAIL]: validateEmail,
  [USER_FORM_FIELDS.MOBILE_PHONE]: validatePhone,
  [USER_FORM_FIELDS.NATIONAL_ID]: validateNationalId,
};
