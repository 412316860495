import { COMMON_REQUEST_DIALOGS } from "@appTypes/dialogs";

export type FormErrors = {
  EMAIL: string[];
  MOBILE_PHONE: string[];
  NATIONAL_ID: string[];
  BIRTH_DATE: string[];
};

export enum USER_VALIDATION_STATUSES {
  SUCCESS = "SUCCESS",
  EXISTS = "EXISTS",
  TEMP_BLOCKED = "TEMP_BLOCKED",
}

export enum USER_VALIDATION_DIALOGS {
  USER_EXISTS = "USER_EXISTS",
  UNVERIFIED_ID_AND_PHONE_BLOCKED = "UNVERIFIED_ID_AND_PHONE_BLOCKED",
}

export enum PHONE_VALIDATION_DIALOGS {
  WRONG_ATTEMPT = "WRONG_ATTEMPT",
  UNVERIFIED_PHONE_BLOCKED = "UNVERIFIED_PHONE_BLOCKED",
}

export interface IUserDialogOptions {
  blockedUntil?: string;
  attempts?: number;
}

export type userDataDialogTypes = USER_VALIDATION_DIALOGS | PHONE_VALIDATION_DIALOGS | COMMON_REQUEST_DIALOGS;
