import { BehaviorSubject, Observable } from "rxjs";

import { IDialogContents } from "@appTypes/dialogs";
import { dialogContents } from "@constants/popups";

import { DEFAULT_POPUP_STATUS } from "./popups.constants";
import { IPopupOptions, IPopupStatus } from "./popups.types";

const dialog$ = new BehaviorSubject<IDialogContents>(dialogContents.defaultDialogContent);
const popupOptions$ = new BehaviorSubject<IPopupOptions>({});
const popupStatus$ = new BehaviorSubject<IPopupStatus>(DEFAULT_POPUP_STATUS);

interface IPopupService {
  setPopupData(dialog?: IDialogContents): IPopupService;
  getPopupData(): Observable<IDialogContents>;
  setPopupStatus(popupStatus?: IPopupStatus): IPopupService;
  getPopupStatus(): Observable<IPopupStatus>;
  setPopupOptions(options?: IPopupOptions): IPopupService;
  getPopupOptions(): Observable<IPopupOptions>;
}

export const popupService: IPopupService = {
  setPopupData: (dialog: IDialogContents): IPopupService => {
    dialog$.next({ ...dialog });

    return popupService;
  },
  getPopupData: (): Observable<IDialogContents> => dialog$.asObservable(),
  setPopupStatus: (popupStatus: IPopupStatus): IPopupService => {
    popupStatus$.next({ ...popupStatus });

    return popupService;
  },
  getPopupStatus: (): Observable<IPopupStatus> => popupStatus$.asObservable(),
  setPopupOptions: (options?: IPopupOptions): IPopupService => {
    if (options) {
      popupOptions$.next({ ...options });
    }

    return popupService;
  },
  getPopupOptions: (): Observable<IPopupOptions> => popupOptions$.asObservable(),
};
