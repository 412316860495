import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    p1: React.CSSProperties;
    p2: React.CSSProperties;
    p3: React.CSSProperties;
    c1: React.CSSProperties;
    c2: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    p1?: React.CSSProperties;
    p2?: React.CSSProperties;
    p3?: React.CSSProperties;
    c1?: React.CSSProperties;
    c2?: React.CSSProperties;
  }

  interface PaletteOptions {
    semantic: SemanticColors;
    light: LightColors;
    greyscale: GrayscaleColors;
    dark: SemanticColors;
  }

  interface Palette {
    semantic: SemanticColors;
    light: LightColors;
    greyscale: GrayscaleColors;
    dark: SemanticColors;
  }

  interface GrayscaleColors {
    grey100: string;
    grey80: string;
    grey64: string;
    grey32: string;
    grey08: string;
    grey04: string;
    grey02: string;
    white: string;
  }

  interface LightColors {
    blue: string;
    navy: string;
    teal: string;
    red: string;
    pink: string;
    positive: string;
    critical: string;
    negative: string;
  }

  interface SemanticColors {
    positive?: string;
    critical?: string;
    negative?: string;
  }

  interface PaletteColor {
    navy?: string;
    teal?: string;
    red?: string;
    pink?: string;
  }

  interface SimplePaletteColorOptions {
    navy?: string;
    teal?: string;
    red?: string;
    pink?: string;
  }

  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    p1: true;
    p2: true;
    p3: true;
    c1: true;
    c2: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#F5B335",
      dark: "#2C2926",
    },
    secondary: {
      main: "#F5B335",
      navy: "#F5B335",
      teal: "#00BED6",
      red: "#FF5C35",
      pink: "#F6A7D7",
    },
    semantic: {
      positive: "#36B37E",
      critical: "#FFAB00",
      negative: "#FF3D31",
    },
    light: {
      blue: "#F0F9FD",
      navy: "#F4F5FC",
      teal: "#F0FBFD",
      red: "#FFF5F3",
      pink: "#FEFAFD",
      positive: "#F3FBF7",
      critical: "#FFFAF0",
      negative: "#FFF4F3",
    },
    greyscale: {
      grey100: "#2C2926",
      grey80: "#464E5B",
      grey64: "#6B727C",
      grey32: "#B5B8BD",
      grey08: "#EDEEEF",
      grey04: "#F6F6F7",
      grey02: "#FAFBFB",
      white: "#FFFFFF",
    },
    dark: {
      positive: "#1F865A",
      negative: "#E22A1F",
    },
    text: {
      primary: "#2C2926",
    },
    error: {
      main: "#E22A1F",
    },
  },
  typography: {
    fontFamily: ["Inter", "Roboto"].join(","),
    allVariants: {
      fontSize: "1.6rem",
    },
    h1: {
      fontSize: "4.8rem",
    },
    h2: {
      fontSize: "4rem",
    },
    h3: {
      fontSize: "3.2rem",
    },
    h4: {
      fontSize: "2.8rem",
    },
    h5: {
      fontSize: "2.4rem",
    },
    p1: {
      fontSize: "2rem",
    },
    p2: {
      fontSize: "1.6rem",
    },
    p3: {
      fontSize: "1.4rem",
    },
    c1: {
      fontSize: "1.2rem",
    },
    c2: {
      fontSize: "1rem",
    },
    subtitle1: {
      fontSize: "2rem",
    },
    subtitle2: {
      fontSize: "1.6rem",
    },
    caption: {
      fontSize: "1.2rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "unset",
          "&:hover": {
            boxShadow: "unset",
          },
        },
        startIcon: {
          ">*:nth-of-type(1)": {
            fontSize: "24px",
          },
        },
      },
      defaultProps: {
        size: "large",
        variant: "contained",
        disableRipple: true,
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "2.4rem",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: "#2C2926",
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
  },
});
