import { styled, Pagination, PaginationItem, PaginationProps } from "@mui/material";

import { COLORS } from "../../constants/enums/COLORS";

const StyledPagination = styled(Pagination)(({ theme }) => ({
  color: theme.palette?.secondary?.navy || COLORS.SECONDARY_NAVY,
  "& .MuiPagination-ul": {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: "8px",
    maxWidth: "fit-content",
  },
}));

const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
  "&.MuiButtonBase-root": {
    backgroundColor: theme.palette?.greyscale?.grey32 || COLORS.GREY_32,
    minWidth: "6px",
    height: "6px",
    border: "unset",
    padding: "unset",
    display: "flex",
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette?.secondary?.navy || COLORS.SECONDARY_NAVY,
    width: "12px",
  },
}));

export const CustomPagination: React.FC<PaginationProps> = ({ count = 3, ...props }) => {
  return (
    <>
      <StyledPagination
        hideNextButton={true}
        hidePrevButton={true}
        count={count}
        renderItem={(item) => (
          <StyledPaginationItem {...item} page={null}>
            3
          </StyledPaginationItem>
        )}
        {...props}
      />
    </>
  );
};
