export enum POPUP_EVENTS {
  AGREE = "AGREE",
  CANCEL = "CANCEL",
  SET_DIALOG = "SET_DIALOG",
}

export enum POPUP_TYPES {
  COMMON = "COMMON",
  WITH_EMAIL = "WITH_EMAIL",
  WITH_COUNTDOWN_IN_TEXT = "WITH_COUNTDOWN_IN_TEXT",
  WITH_COUNTDOWN_LABEL = "WITH_COUNTDOWN_LABEL",
  WRONG_ATTEMPT = "WRONG_ATTEMPT",
  NOT_SELECTED = "",
}
