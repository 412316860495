import { FormControl, FormControlLabel, FormLabel, FormLabelProps, RadioGroup, styled } from "@mui/material";

import { COLORS } from "../../constants/enums/COLORS";
import { CustomRadio } from "../CustomRadio";

interface ISingleFormControlLabel {
  value: string;
  label: string;
  disabled?: boolean;
  key?: string;
}

interface IRadioInputsGroupProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  formControlsArray?: ISingleFormControlLabel[];
  radioGroupDefaultValue?: string;
  radioGroupAriaLabel?: string;
}

interface StyledFormLabelProps extends FormLabelProps {
  component: string;
}

const StyledFormLabel = styled(FormLabel)<StyledFormLabelProps>(({ theme }) => ({
  color: theme.palette?.greyscale?.grey100 || COLORS.GREY_100,
  fontSize: "1.4rem",
  marginBottom: "2px",
  fontWeight: 700,
  "&.Mui-focused": {
    color: theme.palette?.secondary?.navy || COLORS.SECONDARY_NAVY,
  },
}));

export const RadioInputsGroup: React.FC<IRadioInputsGroupProps> = ({
  onChange,
  value,
  label,
  formControlsArray,
  radioGroupAriaLabel,
  radioGroupDefaultValue = "",
}) => {
  return (
    <FormControl component="fieldset">
      {label && <StyledFormLabel component="legend">{label}</StyledFormLabel>}
      <RadioGroup
        sx={{ marginLeft: "2px" }}
        defaultValue={radioGroupDefaultValue}
        {...(radioGroupAriaLabel && { ["aria-label"]: radioGroupAriaLabel })}
        onChange={(event, value) => onChange(value)}
        value={value || radioGroupDefaultValue}
      >
        {!!formControlsArray?.length &&
          formControlsArray.map(({ value, label, key, disabled = false }) => (
            <FormControlLabel
              key={key || label}
              value={value}
              control={<CustomRadio />}
              label={label}
              disabled={disabled}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};
