import { styled } from "@mui/material";
import { Typography } from "@ui-kit";
import { useTranslation } from "react-i18next";

import { ReactComponent as LoaderIcon } from "@assets/icons/loader.svg";

const LoaderWrapper = styled("div")`
  position: fixed;
  background-color: rgba(181, 184, 189, 0.4);
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2147483647;
`;

export const AnimatedLoader = styled(LoaderIcon)`
  transform: scale(1.7);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const SpinnerContainer = styled("div")`
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 15px;
  width: 280px;
  height: 212px;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Loader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <LoaderWrapper>
      <SpinnerContainer>
        <AnimatedLoader />
        <Typography
          variant="p2"
          sx={{
            color: "#6B727C",
            marginTop: "24px",
          }}
        >
          {" "}
          {t("loading")}
        </Typography>
      </SpinnerContainer>
    </LoaderWrapper>
  );
};
