import { useState } from "react";

function useDialog(defaultValue = false): [boolean, () => void, () => void] {
  const [open, setOpen] = useState(defaultValue);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return [open, handleClose, handleOpen];
}

export { useDialog };
