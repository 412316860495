import { FormErrors, FormFields } from "./UpdateDateOfBirth.types";

export const BIRTH_DATE_UNDERAGE_ERROR = "BIRTH_DATE_UNDERAGE_ERROR";

export enum FORM_FIELDS {
  BIRTH_DATE = "BIRTH_DATE",
}

export const DEFAULT_FORM_DATA: FormFields = {
  [FORM_FIELDS.BIRTH_DATE]: "",
};

export const DEFAULT_FORM_ERRORS: FormErrors = {
  [FORM_FIELDS.BIRTH_DATE]: [],
};
