/* eslint-disable */
import { POPUP_EVENTS } from "@constants/popups";

const DEFAULT_EMITTER_CONFIGS = {
  maxListeners: 1,
};
// TODO: add EventEmitter2
interface IEmitterConfigs {
  maxListeners: number;
}

export class PopupEventEmitter {
  private _events: any = {};
  private _configs: IEmitterConfigs = DEFAULT_EMITTER_CONFIGS;

  constructor(configs?: IEmitterConfigs) {
    if (configs) {
      this._configs = configs;
    }
  }

  emit(event: POPUP_EVENTS, cbData: any = undefined): void {
    if (!this._events[event]) return;
    this._events[event].forEach((callback: (args: any) => void) => callback(cbData));
  }

  on(event: POPUP_EVENTS, callback: (data: any) => any): void {
    if (!this._events[event]) this._events[event] = [];

    this._events[event].push(callback);

    if (this._events[event].length === this._configs.maxListeners + 1) {
      this._events[event] = this._events[event].slice(1);
    }
  }

  // TODO: Add logic for several handlers
  removeAllListeners(event: POPUP_EVENTS): void {
    if (!this._events[event]) return;
    delete this._events[event];
  }
}

export const popupEventEmitter = new PopupEventEmitter({ maxListeners: 1 });
