import { Box } from "@mui/material";
import { Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";

import { COMMON_REQUEST_DIALOGS } from "@appTypes/dialogs";
import { CountdownTimer } from "@components/CountdownTimer";
import { TextWithEmail } from "@components/TextWithEmail";
import { SUPPORT_EMAIL } from "@constants/common";
import { ITimeObject } from "@utils/services/Timer/timer.types";

import { LOGIN_DIALOGS } from "../login.types";

// import { PHONE_VALIDATION_DIALOGS, USER_VALIDATION_DIALOGS } from "../SetUserData.types";

interface IDialogOptions {
  remainingAttempts?: number;
  blockingTimer?: ITimeObject<number> | null;
  closeDialog?: () => void;
}

interface IDialogContentSwitcher {
  dialogType?: LOGIN_DIALOGS | COMMON_REQUEST_DIALOGS;
  description?: string | JSX.Element;
  dialogOptions?: IDialogOptions;
}

export const DialogContentsSwitcher: FC<IDialogContentSwitcher> = ({ dialogType, description, dialogOptions }) => {
  const { t } = useTranslation();

  switch (dialogType) {
  case LOGIN_DIALOGS.USER_TEMP_BLOCKED:
    return (
      <>
        <Typography variant="p3" textAlign="center" component="p">
          {description ? t(description as string) : ""}
        </Typography>
        {dialogOptions?.blockingTimer && (
          <Box mt="8px" display="flex" justifyContent="center">
            <Typography
              variant="p3"
              textAlign="center"
              component="p"
              mr="5px"
            >
              {t("labels.retryIn")}
            </Typography>
            <CountdownTimer
              minutes={dialogOptions.blockingTimer.minutes}
              seconds={dialogOptions.blockingTimer.seconds}
              hours={dialogOptions.blockingTimer.hours}
              onTimeoutCallback={dialogOptions.closeDialog || noop}
            />
          </Box>
        )}
      </>
    );
    // TODO: IMPORTANT Will be removed after CR 30 - Q99-2124
  case LOGIN_DIALOGS.USER_BLOCKED:
    return <TextWithEmail textKey={description as string} email={SUPPORT_EMAIL} />;

  case COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG:
  default:
    return (
      <Typography variant="p3" textAlign="center" component="p">
        {description ? t(description as string) : ""}
      </Typography>
    );
  }
};
