import { COMMON_REQUEST_DIALOGS, IDialogWithHandler } from "@appTypes/dialogs";

export interface IDialogOptions {
  email?: string;
  phoneNumber?: string;
  nationalId?: string;
}

export enum PERSONAL_DATA_DIALOGS {
  UNVERIFIED_DATE_OF_BIRTH = "UNVERIFIED_DATE_OF_BIRTH",
}

export type setPasswordDialogs = COMMON_REQUEST_DIALOGS | PERSONAL_DATA_DIALOGS;

export type dialogParameters = IDialogWithHandler<setPasswordDialogs, IDialogOptions>;
