import { Box } from "@mui/material";
import { Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";

import { CountdownTimer } from "@components/CountdownTimer";
import { TextWithCountdownTimer } from "@components/TextWithCountdownTimer";
import { TextWithEmail } from "@components/TextWithEmail";
import { SUPPORT_EMAIL } from "@constants/common";
import { POPUP_TYPES } from "@constants/popups";
import { MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { languages } from "@store/languageService";
import { popupService } from "@store/popups/popups";
import { IPopupOptions } from "@store/popups/popups.types";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";
import { convertDigitsToArabic } from "@utils/services/Text/Text.service";
import { getTimeBefore } from "@utils/services/Timer/Timer.service";

interface SwitcherProps {
  dialogType: POPUP_TYPES;
  description: string | JSX.Element;
  dialogOptions?: IPopupOptions;
  closeDialog(): void;
}

const MAX_RETRY_ATTEMPTS = 3;

export const DialogContentSwitcher: FC<SwitcherProps> = ({
  dialogType,
  description,
  dialogOptions = {
    attempts: 0,
    blockedUntil: "",
  },
  closeDialog,
}) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === languages.AR;

  const redirectToMerchantWebsite = async () => {
    const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, {
      checkoutToken: "",
      merchantUrl: "",
      merchantSuccessUrl: "",
      merchantFailureUrl: "",
      merchantCancelUrl: "",
    });

    popupService.setPopupStatus({ isOpen: false, dialogType: POPUP_TYPES.NOT_SELECTED });
    await logoutWithRedirect(merchantData.merchantUrl);
  };

  switch (dialogType) {
  case POPUP_TYPES.WITH_EMAIL:
    return <TextWithEmail textKey={description as string} email={SUPPORT_EMAIL} />;

  case POPUP_TYPES.WITH_COUNTDOWN_IN_TEXT: {
    // TODO: Possibly make it configurable
    const redirectionTimer = { hours: 0, minutes: 0, seconds: 15 };

    return (
      <TextWithCountdownTimer
        countdownTimer={redirectionTimer}
        textKey={description as string}
        onTimeoutCallback={redirectToMerchantWebsite}
      />
    );
  }

  case POPUP_TYPES.WRONG_ATTEMPT: {
    const remainingAttempts = MAX_RETRY_ATTEMPTS - (dialogOptions?.attempts || 0);

    return (
      <>
        <Typography variant="p3" textAlign="center" component="p">
          {description ? t(description as string) : ""}
        </Typography>
        <Typography
          variant="p3"
          textAlign="center"
          component="p"
          color="#E22A1F"
          mt="8px"
        >
          {t("labels.remaining")}
        </Typography>
        <Typography
          variant="p3"
          textAlign="center"
          component="p"
          mt="8px"
        >
          {convertDigitsToArabic(remainingAttempts, isArabic)} {t("units.trial", { count: remainingAttempts })}
        </Typography>
      </>
    );
  }

  case POPUP_TYPES.WITH_COUNTDOWN_LABEL: {
    const blockingTimer = getTimeBefore((dialogOptions?.blockedUntil as string) || "");

    return (
      <>
        <Typography variant="p3" textAlign="center" component="p">
          {description ? t(description as string) : ""}
        </Typography>
        {blockingTimer && (
          <Box mt="8px" display="flex" justifyContent="center">
            <Typography
              variant="p3"
              textAlign="center"
              component="p"
              mr="5px"
            >
              {t("labels.retryIn")}
            </Typography>
            <CountdownTimer
              minutes={blockingTimer.minutes}
              seconds={blockingTimer.seconds}
              hours={blockingTimer.hours}
              onTimeoutCallback={closeDialog || noop}
            />
          </Box>
        )}
      </>
    );
  }

  case POPUP_TYPES.COMMON:
  default:
    return (
      <Typography variant="p3" textAlign="center" component="p">
        {description ? t(description as string) : ""}
      </Typography>
    );
  }
};
