import { useEffect } from "react";

import { IDialogContents } from "@appTypes/dialogs";

import { popupService } from "./popups";
import { IPopupOptions, IPopupStatus } from "./popups.types";

export const usePopupData = (setPopupData: (popupData: IDialogContents) => void): void => {
  useEffect(() => {
    const popupDataSubscription = popupService
      .getPopupData()
      .subscribe((popupData: IDialogContents) => setPopupData(popupData));

    return () => popupDataSubscription.unsubscribe();
  }, []);
};

export const usePopupStatus = (setPopupStatus: (popupStatus: IPopupStatus) => void): void => {
  useEffect(() => {
    const popupStatusSubscription = popupService
      .getPopupStatus()
      .subscribe((popupStatus: IPopupStatus) => setPopupStatus(popupStatus));

    return () => popupStatusSubscription.unsubscribe();
  });
};

export const usePopupOptions = (setPopupOptions: (popupOptions: IPopupOptions) => void): void => {
  useEffect(() => {
    const popupOptionsSubscription = popupService
      .getPopupOptions()
      .subscribe((popupOptions: IPopupOptions) => setPopupOptions(popupOptions));

    return () => popupOptionsSubscription.unsubscribe();
  });
};
