import { Button as MUButton, ButtonProps, styled } from "@mui/material";
import React from "react";

import { COLORS } from "../../constants/enums/COLORS";

interface IStyledButtonProps extends ButtonProps {
  children: React.ReactChild;
  tertiary?: boolean;
  props?: ButtonProps;
}

export function Button<C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }> & IStyledButtonProps,
): JSX.Element {
  return <StyledButton {...props}>{props.children}</StyledButton>;
}

const StyledButton = styled(MUButton, {
  shouldForwardProp: (prop) => prop !== "tertiary",
})<IStyledButtonProps>(({ theme, tertiary }) => {
  return {
    color: COLORS.PRIMARY_MAIN,
    borderRadius: "8px",
    backgroundColor: COLORS.PRIMARY_DARK,
    height: "58px",
    padding: "14px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "14px 20px",
    },
    fontSize: "1.6rem",
    boxShadow: "unset",
    textTransform: "unset",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_MAIN,
      color: COLORS.WHITE,
      boxShadow: "unset",
    },
    "&:disabled": {
      backgroundColor: theme.palette?.greyscale?.grey04 || COLORS.GREY_04,
      color: theme.palette?.greyscale?.grey32 || COLORS.GREY_32,
    },
    "&.MuiButton-sizeSmall": {
      height: "40px",
      fontSize: "1.4rem",
      padding: "8px 24px",
    },
    "&.MuiButton-containedSecondary": {
      backgroundColor: theme.palette?.greyscale?.grey04 || COLORS.GREY_04,
      color: theme.palette?.primary?.dark || COLORS.PRIMARY_DARK,
      "&:hover": {
        backgroundColor: theme.palette?.greyscale?.grey04 || COLORS.GREY_04,
      },
    },
    ...(tertiary && {
      backgroundColor: "transparent",
      color: theme.palette?.text?.primary || COLORS.GREY_100,
      "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "unset",
      },
    }),
  };
});
