import { styled, Switch, SwitchProps } from "@mui/material";

import { COLORS } from "../../constants/enums/COLORS";

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: theme.palette?.greyscale?.white || COLORS.WHITE,
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette?.secondary?.main || COLORS.SECONDARY_MAIN,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      opacity: 0.7,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    backgroundColor: theme.palette?.greyscale?.grey08 || COLORS.GREY_08,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const CustomSwitch: React.FC<SwitchProps> = (props) => {
  return <StyledSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />;
};
