import i18n from "i18next";
import { BehaviorSubject, Observable } from "rxjs";

import { languages } from "./language.constants";

export interface ILanguage {
  languageCode?: languages;
}

// TODO: Possibly default language should determine depending on locale in user browser

const language$ = new BehaviorSubject<ILanguage>({ languageCode: undefined });

export const languageService = {
  getValue: (): ILanguage => language$.value,
  setLanguage: (languageCode?: languages): void => {
    language$.next({ ...language$.value, languageCode });
    i18n.changeLanguage(languageCode);
  },
  getLanguage: (): Observable<ILanguage> => language$.asObservable(),
};
