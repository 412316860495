import { ORDER_STATUSES } from "@constants/business/orderStatuses";

export enum E_PROMISSORY_NOTE_STATE {
  UNDEFINED = "UNDEFINED",
  NOT_NEED = "NOT_NEED",
  WAITING = "WAITING",
  SIGNED = "SIGNED",
  REJECTED = "REJECTED",
}
export interface IContractInfo {
  timer: string;
  isNoteSigned: boolean;
  orderId: string;
  status: keyof typeof ORDER_STATUSES;
  promissoryNoteState: E_PROMISSORY_NOTE_STATE;
}

export interface IContractTokenInfo extends IContractInfo {
  merchantUrl?: string;
}
