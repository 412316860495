import { AxiosResponse } from "axios";

import { COMMON_REQUEST_DIALOGS, IDialogInfo } from "@appTypes/dialogs";
import { DEFAULT_USER_FORM_DATA } from "@constants/defaults/userData";
import { OK as HTTP_OK } from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { network } from "@utils/network/network";

import { userExistsDialog } from "../SetUserData.constants";
import { IUserDialogOptions, USER_VALIDATION_DIALOGS, USER_VALIDATION_STATUSES } from "../SetUserData.types";

export interface IRequestResult
  extends IDialogInfo<USER_VALIDATION_DIALOGS | COMMON_REQUEST_DIALOGS, IUserDialogOptions> {
  isSuccess: boolean;
}

interface IPersonalDataValidationResponse {
  result: keyof typeof USER_VALIDATION_STATUSES;
  blockedUntil: string;
}

type userFormData = typeof DEFAULT_USER_FORM_DATA;

type useRequestType = { validateUser: (userFormData: userFormData) => Promise<IRequestResult> };

export const useVerifyUserData = (): useRequestType => {
  const validateUserRequest = (userFormData: userFormData): Promise<AxiosResponse<IPersonalDataValidationResponse>> => {
    const userData = {
      email: userFormData.EMAIL,
      phoneNumber: `+966${userFormData.MOBILE_PHONE}`,
      dateOfBirth: userFormData.BIRTH_DATE,
      uid: userFormData.NATIONAL_ID,
    };

    return network.request<IPersonalDataValidationResponse>("connect", "customer/personal-data/validation", {
      method: "POST",
      withCredentials: process.env.REACT_APP_WITH_MOCK_SERVER !== "true",
      data: userData,
    });
  };

  const validateUser = async (userData: userFormData): Promise<IRequestResult> => {
    const { data, status } = await validateUserRequest(userData);

    if (status === HTTP_OK) {
      switch (data.result) {
      case USER_VALIDATION_STATUSES.SUCCESS:
        return { isSuccess: true };

      case USER_VALIDATION_STATUSES.EXISTS:
        return {
          isSuccess: false,
          dialog: userExistsDialog,
          dialogType: USER_VALIDATION_DIALOGS.USER_EXISTS,
        };

      case USER_VALIDATION_STATUSES.TEMP_BLOCKED:
        return {
          isSuccess: false,
          dialog: dialogContents.userLockedByRegAttempts,
          dialogType: USER_VALIDATION_DIALOGS.UNVERIFIED_ID_AND_PHONE_BLOCKED,
          isBlockingPopup: true,
          dialogOptions: {
            blockedUntil: data.blockedUntil,
          },
        };

      default:
        return {
          isSuccess: false,
          dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
          dialog: dialogContents.somethingWentWrong,
        };
      }
    }

    return {
      isSuccess: false,
      dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
      dialog: dialogContents.somethingWentWrong,
    };
  };

  return { validateUser };
};
