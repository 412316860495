import { CLIENT_CONNECT } from "@constants/api";
import { IAuthorization } from "@store/authorization/authorization";
import { IAxiosResponseWithError, network } from "@utils/network/network";

export const refreshTokenRequest = async (
  refreshToken?: string,
  clientId = CLIENT_CONNECT,
): Promise<IAxiosResponseWithError<IAuthorization>> => {
  const requestData = {
    client_id: clientId,
    ...(refreshToken ? { refresh_token: refreshToken } : {}),
  };

  return await network.request<IAuthorization>("connect", "customer/token/refreshment", {
    method: "POST",
    data: requestData,
  });
};
