import { Box, styled } from "@mui/material";
import { COLORS, Typography } from "@ui-kit";
import { FC } from "react";

import sandClockGif from "@assets/uiImages/sand_clock_animation.gif";

interface IHeaderProps {
  headerText: string;
  insideConfirmationComponent?: boolean;
}

export const HeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "insideConfirmationComponent",
})<{ insideConfirmationComponent?: boolean }>(({ insideConfirmationComponent }) => ({
  background: COLORS.WHITE,
  borderRadius: "16px",
  padding: "16px",
  display: "flex",
  alignItems: "center",
  border: insideConfirmationComponent ? `1px solid ${COLORS.GREY_08}` : "none",
  marginBottom: insideConfirmationComponent ? "20px" : 0,
}));

export const HeaderWithStatusImg: FC<IHeaderProps> = ({ headerText, insideConfirmationComponent }) => {
  return (
    <HeaderContainer insideConfirmationComponent={insideConfirmationComponent}>
      <img
        alt="sandClock"
        src={sandClockGif}
        style={{
          height: "64px",
        }}
      />

      <Typography fontWeight="bold">{headerText}</Typography>
    </HeaderContainer>
  );
};
