import { InputAdornment, IconButton, useTheme } from "@mui/material";
import { useState } from "react";

import { ReactComponent as Hide } from "../../../assets/icons/hide.svg";
import { ReactComponent as Show } from "../../../assets/icons/show.svg";
import { IAbstractInputProps } from "../AbstractInput/AbstractInput";
import { BaseInput } from "../BaseInput";

export const PasswordInput: React.FC<IAbstractInputProps> = ({ label, labelId, ...props }) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <BaseInput
        label={label}
        labelId={labelId}
        endAdornment={
          <InputAdornment position={theme.direction === "ltr" ? "end" : "start"}>
            <IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <Hide /> : <Show />}</IconButton>
          </InputAdornment>
        }
        type={showPassword ? "text" : "password"}
        {...props}
      />
    </>
  );
};
