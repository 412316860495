import { BehaviorSubject, Observable } from "rxjs";

export interface ILocation {
  path: string;
  from: string;
}

const location$ = new BehaviorSubject<ILocation>({ path: "", from: "" });
const isLoading$ = new BehaviorSubject<boolean>(false);

export const locationService = {
  getLocationValue: (): ILocation => location$.value,
  setLocation: (location: Partial<ILocation>): void => location$.next({ ...location$.getValue(), ...location }),
  getLocation: (): Observable<ILocation> => location$.asObservable(),
  setLoadingFlag: (isLoading: boolean): void => isLoading$.next(isLoading),
  getLoadingFlag: (): Observable<boolean> => isLoading$.asObservable(),
};
