import { Box, styled } from "@mui/material";
import { FC, useState } from "react";

import { COLORS } from "../../constants/enums/COLORS";
import { CreditLimitItem } from "./components/CreditLimitItem";
import { FixedHeader } from "./components/FixedHeader";
import { PerMonthHeader } from "./components/PerMonthHeader";
import { PeriodHeader } from "./components/PeriodHeader";

export const CreditLimitsContainer = styled(Box)(({ theme }) => ({
  paddingLeft: 0,
  display: "flex",
  overflowY: "auto",
  scrollbarColor: "rgba(107, 114, 124, 0.24) white",
  scrollbarWidth: "thin",
  margin: 0,
  "::-webkit-scrollbar": {
    display: "none",
  },
  "::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: theme.palette?.greyscale?.grey64 || COLORS.GREY_64,
    borderRadius: "100px",
  },
}));

// const MOCK_CREDIT_LIMITS = [
//   {
//     id: "item_1",
//     disabled: false,
//     header: {
//       currency: "SAR",
//       cost: "99.00",
//       period: "24 month"
//     },
//     label: "Split your payment into fixed SAR 99.00 monthly",
//   },
//   {
//     id: "item_2",
//     disabled: false,
//     header: {
//       currency: "SAR",
//       cost: "299.00",
//       period: "24 month"
//     },
//     label: "Another label",
//   },
//   {
//     id: "item_3",
//     disabled: true,
//     header: {
//       currency: "SAR",
//       cost: "299.00",
//       period: "24 month"
//     },
//     label: "Sorry you are not eligible for this program",
//   },
//   {
//     id: "item_4",
//     disabled: false,
//     header: {
//       unit: "months",
//       period: "Pay in 3-6"
//     },
//     label: "Select from 3-6 months to pay without any interest.",
//   },
// ];

export const CreditLimits: FC = () => {
  const [activeItem, setActiveItem] = useState("");

  return (
    <CreditLimitsContainer>
      <CreditLimitItem
        onClick={(item) => setActiveItem(item)}
        isChosen={"item_1" === activeItem}
        label="Split your payment into fixed SAR 99.00 monthly"
        id="item_1"
        header={<PerMonthHeader currency="SAR" cost="199.00" period="/ 12month" />}
      />
      <CreditLimitItem
        onClick={(item) => setActiveItem(item)}
        isChosen={"item_2" === activeItem}
        label="Split your payment into fixed SAR 99.00 monthly"
        id="item_2"
        header={<PerMonthHeader currency="SAR" cost="199.00" period="/ 12month" />}
      />
      <CreditLimitItem
        onClick={(item) => setActiveItem(item)}
        isChosen={"item_3" === activeItem}
        label="Split your payment into fixed SAR 99.00 monthly"
        disabled
        id="item_3"
        header={<FixedHeader currency="SAR" cost="199.00" headerPrefix="Fixed" />}
      />
      <CreditLimitItem
        onClick={(item) => setActiveItem(item)}
        isChosen={"item_4" === activeItem}
        label="Split your payment into fixed SAR 99.00 monthly"
        id="item_4"
        header={<PeriodHeader period="Pay in 3-6" unit="months" />}
      />
    </CreditLimitsContainer>
  );
};
