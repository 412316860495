import { styled } from "@mui/material";

import { COLORS } from "../../constants/enums/COLORS";
import { Button } from "../Button";

interface IFiltersElement {
  label: string;
  key?: string;
}

interface IFiltersProps {
  filtersArray: Array<IFiltersElement>;
  chosenValues: Array<string>;
  onClick: (value: string) => void;
}

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.greyscale?.white || COLORS.WHITE,
  padding: "8px 16px",
  height: "40px",
  borderRadius: "40px",
  fontSize: "1.4rem",
  fontWeight: 500,
  marginRight: "8px",
  color: theme.palette?.greyscale?.grey64 || COLORS.GREY_64,
  "&:hover": {
    color: theme.palette?.secondary?.main || COLORS.SECONDARY_MAIN,
    backgroundColor: theme.palette?.greyscale?.white || COLORS.WHITE,
  },
  "&.selected": {
    color: theme.palette?.secondary?.main || COLORS.SECONDARY_MAIN,
    backgroundColor: theme.palette?.light?.navy || COLORS.LIGHT_NAVY,
  },
}));

export const Filters: React.FC<IFiltersProps> = ({ filtersArray, chosenValues, onClick }) => {
  return (
    <div>
      {!!filtersArray?.length &&
        filtersArray.map(({ label, key }) => (
          <StyledButton
            key={key || label}
            className={chosenValues.includes(label) ? "selected" : ""}
            onClick={() => onClick(label)}
          >
            {label}
          </StyledButton>
        ))}
    </div>
  );
};
