import { styled } from "@mui/material";

import LogoAr from "@assets/uiImages/JAKArLogo.svg";
import Logo from "@assets/uiImages/JAKLogo.svg";
import { languages, languageService } from "@store/languageService";

import { LanguagePanel } from "../LanguagePanel/LanguagePanel";

const LogoIcon = styled("img")`
  height: 30px;
`;

export const DesktopMenu: React.FC = () => {
  return (
    <>
      <LogoIcon src={languageService.getValue().languageCode == languages.AR ? LogoAr : Logo} />
      <LanguagePanel />
    </>
  );
};

DesktopMenu.displayName = "DesktopMenu";
