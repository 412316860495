import { styled, Alert, AlertProps } from "@mui/material";

import { ReactComponent as Checkmark } from "../../assets/icons/feedback_checkmark.svg";
import { ReactComponent as Cross } from "../../assets/icons/feedback_cross.svg";
import { COLORS } from "../../constants/enums/COLORS";

interface IFeedbackProps extends AlertProps {
  displayIcon?: boolean;
}

const StyledAlert = styled(Alert)(({ theme, severity }) => ({
  padding: "0 8px",
  fontSize: "1.4rem",
  height: "24px",
  width: "fit-content",
  borderRadius: "12px",
  fontWeight: 600,
  "& .MuiAlert-message": {
    padding: "0",
    margin: "0 6px;",
  },
  "& .MuiAlert-icon": {
    alignItems: "center",
    margin: "0",
  },
  ...(severity === "success" && {
    color: theme.palette?.dark?.positive || COLORS.DARK_POSITIVE,
    backgroundColor: theme.palette?.light?.positive || COLORS.LIGHT_POSITIVE,
  }),
  ...(severity === "error" && {
    color: theme.palette?.dark?.negative || COLORS.DARK_NEGATIVE,
    backgroundColor: theme.palette?.light?.negative || COLORS.LIGHT_NEGATIVE,
  }),
  ...(severity === "info" && {
    color: theme.palette?.secondary?.main || COLORS.SECONDARY_MAIN,
    backgroundColor: theme.palette?.light?.navy || COLORS.LIGHT_NAVY,
  }),
  ...(severity === "warning" && {
    color: theme.palette?.dark?.critical || COLORS.DARK_CRITICAL,
    backgroundColor: theme.palette?.light?.critical || COLORS.LIGHT_CRITICAL,
  }),
}));

export const Feedback: React.FC<IFeedbackProps> = ({ children, displayIcon = true, ...props }) => {
  return (
    <>
      <StyledAlert
        iconMapping={{
          success: displayIcon ? <Checkmark /> : null,
          error: displayIcon ? <Cross /> : <></>,
          info: <></>,
          warning: <></>,
        }}
        {...props}
      >
        {children}
      </StyledAlert>
    </>
  );
};
