import { styled } from "@mui/material";
import { COLORS } from "@ui-kit";

export const ResendButton = styled("button")(() => ({
  border: "unset",
  backgroundColor: "transparent",
  padding: 0,
  fontFamily: "inherit",
  "& .MuiTypography-root": {
    color: COLORS.PRIMARY_DARK,
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
