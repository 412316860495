import { InputAdornment, useTheme } from "@mui/material";
import { forwardRef } from "react";

import { ReactComponent as Checkmark } from "../../../assets/icons/checkmark_green.svg";
import { ReactComponent as Danger } from "../../../assets/icons/danger_red.svg";
import { IAbstractInputProps, AbstractInput } from "../AbstractInput/AbstractInput";

export const BaseInput: React.FC<IAbstractInputProps> = forwardRef(({ label, labelId, ...props }, ref) => {
  const theme = useTheme();
  return (
    <>
      <AbstractInput
        label={label}
        labelId={labelId}
        endAdornment={
          props.errorText ? (
            <InputAdornment position={theme.direction === "ltr" ? "end" : "start"}>
              <Danger />
            </InputAdornment>
          ) : props.successText ? (
            <InputAdornment position={theme.direction === "ltr" ? "end" : "start"}>
              <Checkmark />
            </InputAdornment>
          ) : null
        }
        ref={ref}
        {...props}
      />
    </>
  );
});

BaseInput.displayName = "BaseInput";
