import { useState } from "react";

import { Loader } from "@components/Loader";
import { useLoadingFlag } from "@store/location/locationHooks";

interface ILoaderProviderProps {
  children: React.ReactNode;
}

export const LoaderProvider: React.FC<ILoaderProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  useLoadingFlag(setIsLoading);

  return (
    <>
      {isLoading && <Loader />}
      {children}
    </>
  );
};
