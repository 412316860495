import MUIStepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import MUIStepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";

export const StepConnector = styled(MUIStepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 14px)",
    right: "calc(50% + 14px)",
    [theme.breakpoints.down("sm")]: {
      left: "calc(-50% + 10px)",
      right: "calc(50% + 10px)",
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.navy,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.navy,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

export const StepLabel = styled(MUIStepLabel)(({ theme }) => ({
  "& .Mui-disabled": {
    color: theme.palette.greyscale.grey32,
  },
}));
