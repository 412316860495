import { IDialogInfo } from "@appTypes/dialogs";
import { EMPLOYMENT_SECTORS } from "@constants/employmentsSectors";
import { OK as HTTP_OK } from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { IAxiosResponseWithError, network } from "@utils/network/network";

export enum SAFETY_CHECK_DIALOG_TYPES {
  SAFETY_CHECK_DIALOG_REQUEST_FAILED = "SAFETY_CHECK_DIALOG_REQUEST_FAILED",
}

export interface ISafetyCheckResponse {
  isRiskScoreActual: boolean;
  isSysEmploymentActual: boolean;
  selectedSector: EMPLOYMENT_SECTORS;
}

export interface ISafetyCheckResult extends IDialogInfo<SAFETY_CHECK_DIALOG_TYPES, null> {
  isSuccess: boolean;
  result?: ISafetyCheckResponse;
}

type useCheckSRSReturnType = { safetyCheck: () => Promise<ISafetyCheckResult> };

export const useSafetyCheck = (): useCheckSRSReturnType => {
  const safetyCheckRequest = (): Promise<IAxiosResponseWithError<ISafetyCheckResponse>> => {
    return network.request<ISafetyCheckResponse>("connect", "customer/safety-check", {
      method: "GET",
    });
  };

  const safetyCheck = async (): Promise<ISafetyCheckResult> => {
    const { data, status } = await safetyCheckRequest();

    if (status === HTTP_OK) {
      return { isSuccess: true, result: data };
    }

    return {
      isSuccess: false,
      dialogType: SAFETY_CHECK_DIALOG_TYPES.SAFETY_CHECK_DIALOG_REQUEST_FAILED,
      dialog: dialogContents.somethingWentWrong,
    };
  };

  return { safetyCheck };
};
