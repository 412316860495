import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Typography } from "@ui-kit";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { languages, languageService, useLanguageCode } from "@store/languageService";
import { menuService, useMenuAdditionalContent, useMenuInfo } from "@store/menu";
import { IMenuAdditionalInfo, IMenuInfo } from "@store/menu/menu.types";

import { MobileHeaderSwitcher } from "../customHeaders/MobileHeaderSwitcher";
import { Header, HeaderText, MenuButton, MenuContainer } from "./MobileMenu.components";

export const MobileMenu: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [menuInfo, setMenuInfo] = useState<IMenuInfo>();
  const [additionalMenuContent, setAdditionalContent] = useState<IMenuAdditionalInfo>();
  const [selectedLang, setSelectedLang] = useState<languages | undefined>();

  useLanguageCode((langCode?: languages) => {
    const { leftSide, rightSide, needIcon } = menuService.getMenuDataValue();

    setSelectedLang(langCode);
    setMenuInfo({
      leftSide: t(leftSide, { lng: langCode }),
      rightSide: rightSide ? t(rightSide, { lng: langCode }) : "",
      needIcon,
    });
  });
  useMenuInfo(({ leftSide, rightSide, needIcon }: IMenuInfo) => {
    setMenuInfo({ leftSide: t(leftSide), rightSide: rightSide ? t(rightSide) : "", needIcon });
  });
  useMenuAdditionalContent(setAdditionalContent);

  const handleLanguageChange = (langCode: languages) => () => {
    languageService.setLanguage(langCode);
  };
  const languageToSwitch = selectedLang === languages.EN ? languages.AR : languages.EN;
  const switchLangButtonText = selectedLang === languages.EN ? "العربيه" : "English";

  return (
    <MenuContainer width="100%">
      <Header
        container
        style={{ justifyContent: additionalMenuContent?.isAdditionalContentNeeded ? "flex-end" : "space-between" }}
      >
        {!additionalMenuContent?.isAdditionalContentNeeded && (
          <HeaderText item alignContent="center">
            <Typography variant={isMobile ? "p1" : "h5"} fontWeight="bold">
              {menuInfo?.leftSide}
            </Typography>
            {menuInfo?.rightSide && (
              <Typography variant={isMobile ? "p1" : "h5"} fontWeight="bold">
                {menuInfo?.rightSide}
              </Typography>
            )}
          </HeaderText>
        )}
        <Grid item>
          <MenuButton
            style={{ fontSize: "1.4rem", lineHeight: "24px", borderRadius: "8px" }}
            onClick={handleLanguageChange(languageToSwitch)}
          >
            {switchLangButtonText}
          </MenuButton>
        </Grid>
      </Header>
      <MobileHeaderSwitcher />
    </MenuContainer>
  );
};
