import { FormErrors, FormFields } from "./forgotPassword.types";

export const PHONE_NOT_VALID_ERROR = "PHONE_NOT_VALID_ERROR";

export enum FORM_FIELDS {
  MOBILE_PHONE = "MOBILE_PHONE",
  NATIONAL_ID = "NATIONAL_ID",
}

export const DEFAULT_FORM_DATA: FormFields = {
  [FORM_FIELDS.MOBILE_PHONE]: "",
  [FORM_FIELDS.NATIONAL_ID]: "",
};

export const DEFAULT_FORM_ERRORS: FormErrors = {
  [FORM_FIELDS.MOBILE_PHONE]: [],
  [FORM_FIELDS.NATIONAL_ID]: [],
};

export const DEFAULT_RESET_PASSWORD_DATA = {
  form: { ...DEFAULT_FORM_DATA },
};
