import { useEffect } from "react";

import { IAuthorization, ILogInInfo, authorizationStore } from "./authorization";

export const useAuthorization = (setAuthInfo: (authorizationInfo: IAuthorization) => void): void => {
  useEffect(() => {
    const locationSubscription = authorizationStore
      .getAuthorization()
      .subscribe((authorizationInfo: IAuthorization) => setAuthInfo(authorizationInfo));

    return () => locationSubscription.unsubscribe();
  }, []);
};

export const useLogInStatus = (setLoggedInInfo: (loggedInInfo: ILogInInfo) => void): void => {
  useEffect(() => {
    const locationSubscription = authorizationStore
      .getLoggedInStatus()
      .subscribe((loggedInInfo: ILogInInfo) => setLoggedInInfo(loggedInInfo));

    return () => locationSubscription.unsubscribe();
  });
};
