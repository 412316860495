import { IconButton, styled } from "@mui/material";
import { useRef, useState } from "react";

import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete_icon.svg";
import { COLORS } from "../../../constants/enums/COLORS";
import { Typography } from "../../Typography/Typography";
import { StyledLabel } from "../inputsStyles";

interface IFileUploaderProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string;
  labelId: string;
  maxFileSizeInBytes?: number;
  updateFilesCallback?: (files: File[]) => void;
}

interface IUploadFileInputContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  hasFiles: boolean;
}

const UploadFileInputContainer = styled("div", {
  shouldForwardProp: (propName) => propName !== "hasFiles",
})<IUploadFileInputContainerProps>(({ theme, hasFiles }) => ({
  backgroundColor: theme.palette?.greyscale?.grey04 || COLORS.GREY_04,
  display: hasFiles ? "grid" : "flex",
  maxWidth: "fit-content",
  minHeight: "56px",
  minWidth: "327px",
  borderRadius: "8px",
  ...(hasFiles && {
    gridTemplateColumns: "1fr 79px",
  }),
}));

const UploadButton = styled("button")(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette?.secondary?.main || COLORS.SECONDARY_MAIN,
  height: "auto",
  width: "100%",
  borderRadius: "8px",
  border: "unset",
  boxShadow: "unset",
  fontWeight: 700,
  fontSize: "1.4rem",
  fontFamily: "Inter",
  "&:hover": {
    cursor: "pointer",
  },
}));

const UploadedFileContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const UploadedFilesList = styled("div")`
  display: grid;
  grid-auto-flow: row;
  padding: 10px;
`;

const StyledIconButton = styled(IconButton)`
  :hover {
    background-color: unset;
  }
`;

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;

export const FileUploader: React.FC<IFileUploaderProps> = ({
  label,
  labelId,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  updateFilesCallback,
  ...otherProps
}) => {
  const [files, setFiles] = useState<{ [key: string]: File }>({});

  const fileInputFieldRef = useRef<HTMLInputElement>(null);
  const handleUploadBtnClick = () => {
    if (fileInputFieldRef?.current) {
      fileInputFieldRef.current.click();
    }
  };

  const addNewFiles = (newFiles: FileList) => {
    for (const file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps?.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const handleNewFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files: newFiles } = e.target;
    if (newFiles?.length) {
      const updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCallback(updatedFiles);
    }
  };

  const convertNestedObjectToArray = (nestedObj: { [key: string]: File }) => {
    return Object.keys(nestedObj).map((key) => nestedObj[key]);
  };

  const removeFile = (fileName: string) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCallback({ ...files });
  };

  const callUpdateFilesCallback = (files: { [key: string]: File }) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCallback && updateFilesCallback(filesAsArray);
  };
  return (
    <>
      <StyledLabel htmlFor={labelId}>{label}</StyledLabel>
      <UploadFileInputContainer hasFiles={!!Object.keys(files)?.length}>
        {!Object.keys(files)?.length ? (
          <>
            <input
              type="file"
              id={labelId}
              ref={fileInputFieldRef}
              onChange={handleNewFileUpload}
              title=""
              value=""
              style={{ display: "none" }}
              {...otherProps}
            />
            <UploadButton onClick={handleUploadBtnClick} type="button">
              Upload File
            </UploadButton>
          </>
        ) : (
          <>
            <UploadedFilesList>
              {Object.keys(files).map((fileName: string) => {
                const file = files[fileName];
                return (
                  <UploadedFileContainer key={fileName}>
                    <StyledIconButton onClick={() => removeFile(fileName)}>
                      <DeleteIcon />
                    </StyledIconButton>
                    <div>
                      <Typography variant="p3" fontWeight="bold" paragraph sx={{ margin: 0 }}>
                        {file.name}
                      </Typography>
                    </div>
                  </UploadedFileContainer>
                );
              })}
            </UploadedFilesList>
            <>
              <input
                type="file"
                id={labelId}
                ref={fileInputFieldRef}
                onChange={handleNewFileUpload}
                title=""
                value=""
                style={{ display: "none" }}
                {...otherProps}
              />
              <UploadButton onClick={handleUploadBtnClick} type="button">
                Browse
              </UploadButton>
            </>
          </>
        )}
      </UploadFileInputContainer>
    </>
  );
};
