import { Box, styled } from "@mui/system";
import { Typography } from "@ui-kit";
import { useState } from "react";

import { languages, languageService, useLanguageCode } from "@store/languageService";

const FontsContainer = styled(Box)`
  width: 128px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  direction: ltr;
`;

const LanguageItem = styled(Box)<{ isSelected: boolean }>`
  font-weight: ${({ isSelected }) => (isSelected ? "bold" : "normal")};
  &:hover {
    cursor: pointer;
  }
`;

export const LanguagePanel: React.FC = () => {
  const [selectedLang, setSelectedLang] = useState<languages | undefined>();

  const handleLangClick = (langCode: languages) => () => {
    languageService.setLanguage(langCode);
  };

  useLanguageCode(setSelectedLang);

  return (
    <FontsContainer>
      <LanguageItem isSelected={selectedLang === languages.EN} onClick={handleLangClick(languages.EN)}>
        <Typography variant="p2">English</Typography>
      </LanguageItem>
      <LanguageItem isSelected={selectedLang === languages.AR} onClick={handleLangClick(languages.AR)}>
        <Typography variant="p2" fontFamily="Cairo">
          عربي
        </Typography>
      </LanguageItem>
    </FontsContainer>
  );
};
