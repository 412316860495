import { Grid, LinearProgress, LinearProgressProps, styled, useMediaQuery, useTheme } from "@mui/material";

import { COLORS } from "../../constants/enums/COLORS";
import { Typography } from "../Typography";

export type strengthType = "Insufficient" | "Low" | "Medium" | "Great";

interface IPasswordStrengthIndicatorProps {
  strengthCode: strengthType;
  label: string;
}

interface StyledLinearProgressProps extends LinearProgressProps {
  strength: strengthType;
}

const strengthMap = new Map<strengthType, { color: COLORS; value: number }>([
  ["Low", { color: COLORS.SEMANTIC_NEGATIVE, value: 25 }],
  ["Medium", { color: COLORS.SEMANTIC_CRITICAL, value: 50 }],
  ["Great", { color: COLORS.SEMANTIC_POSITIVE, value: 100 }],
]);

const PasswordStrengthIndicatorWrapper = styled(Grid)`
  margin-top: 8px;
`;

const StyledLinearProgress = styled(LinearProgress)<StyledLinearProgressProps>(({ theme, strength }) => ({
  height: 2,
  borderRadius: "8px",
  backgroundColor: theme.palette?.greyscale?.grey08 || COLORS.GREY_08,
  width: "100%",
  "& .MuiLinearProgress-bar": {
    backgroundColor: `${strengthMap.get(strength)?.color}`,
  },
}));

export const PasswordStrengthIndicator: React.FC<IPasswordStrengthIndicatorProps> = ({ strengthCode, label }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PasswordStrengthIndicatorWrapper container>
      <Grid item xs={3} textAlign={theme.direction === "rtl" ? "right" : "left"}>
        <Typography
          variant={isMobile ? "c2" : "c1"}
          fontWeight="bold"
          sx={{ color: strengthMap.get(strengthCode)?.color }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <StyledLinearProgress
          value={strengthMap.get(strengthCode)?.value}
          variant="determinate"
          strength={strengthCode}
        />
      </Grid>
    </PasswordStrengthIndicatorWrapper>
  );
};
