import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { SetupPassword } from "@components/SetupPassword";
import { DEFAULT_USER_FORM_DATA } from "@constants/defaults/userData";
import * as httpCodes from "@constants/httpStatuses";
import { DEFAULT_MERCHANT_DATA_KEY } from "@constants/sessionStorageDefaults";
import { MERCHANT_DATA_KEY, REGISTRATION_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { authorizationStore } from "@store/authorization";
import { languages } from "@store/languageService";
import { menuService } from "@store/menu";
import { useCheckSRS } from "@utils/hooks/useCheckSRS/useCheckSRS";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { getMerchantURLs } from "@utils/network/customerInfo/getMerchantURLs";

import { dialogParameters, PERSONAL_DATA_DIALOGS } from "./SetUserPassword.types";
import { SetUserPasswordDialogs } from "./components/SetUserPasswordDialogs";
import { useCreateUser } from "./hooks/useCreateUser";
import { useVerifyPersonalData } from "./hooks/usePersonalData";

const DEFAULT_REGISTRATION_DATA = {
  form: { ...DEFAULT_USER_FORM_DATA },
};

export const SetUserPassword: FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [dialogInfo, setDialogInfo] = useState<dialogParameters>();
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [userRegistrationData] = useSessionStorageState<typeof DEFAULT_REGISTRATION_DATA>(
    REGISTRATION_KEY,
    DEFAULT_REGISTRATION_DATA,
  );
  const [merchantStorageValue, setCheckoutTokenToStorage] = useSessionStorageState(
    MERCHANT_DATA_KEY,
    DEFAULT_MERCHANT_DATA_KEY,
  );

  const { createUser } = useCreateUser();
  const { personalDataVerify } = useVerifyPersonalData();
  const { checkSRS } = useCheckSRS();

  const handleContinueClick = useCallback(async (passwordInputValue = "") => {
    const createUserReq = async (password = ""): Promise<void> => {
      const { isSuccess, ...createUserResponse } = await createUser(password);

      if (isSuccess) {
        createUserResponse?.token && authorizationStore.setAuthorization(createUserResponse.token);
        getMerchanturls();

        const validateDOBReq = async () => {
          const { isSuccess, ...validateDataDialog } = await personalDataVerify();

          if (isSuccess) {
            await checkSRS();

            history.push(siteMap.EmploymentDetailsPage.path);
          } else if (validateDataDialog.dialogType === PERSONAL_DATA_DIALOGS.UNVERIFIED_DATE_OF_BIRTH) {
            const { EMAIL, MOBILE_PHONE, NATIONAL_ID } = userRegistrationData.form;

            setDialogInfo({
              ...validateDataDialog,
              dialogOptions: {
                email: EMAIL,
                phoneNumber: i18n.language === languages.EN ? `+966${MOBILE_PHONE}` : `966${MOBILE_PHONE}+`,
                nationalId: NATIONAL_ID,
              },
            });
            openDialog();
          } else {
            setDialogInfo({ ...validateDataDialog, lastAction: validateDOBReq });
            openDialog();
          }
        };

        validateDOBReq();
      } else {
        setDialogInfo({ ...createUserResponse, lastAction: createUserReq, parameters: [password] });
        openDialog();
      }
    };

    await createUserReq(passwordInputValue);
  }, []);

  const handleCloseDialog = useCallback(() => {
    closeDialog();
    setDialogInfo({});
  }, [closeDialog]);

  useEffect(() => {
    menuService.setMenuData({ leftSide: "pageHeader.setupPassword", rightSide: "", needIcon: false });
  }, []);

  const handleGoBack = useCallback(() => {
    history.push(siteMap.CreateAccountPage.path);
  }, []);

  const getMerchanturls = async () => {
    try {
      const { data, status } = await getMerchantURLs(merchantStorageValue.checkoutToken);
      if (status === httpCodes.OK) {
        setCheckoutTokenToStorage({
          ...merchantStorageValue,
          orderId: data.orderId,
          merchantUrl: data.merchantUrl,
          merchantSuccessUrl: data.success,
          merchantFailureUrl: data.failure,
          merchantCancelUrl: data.cancel,
        });
      } else {
        console.log("User info request status:", status);
      }
    } catch (e) {
      console.log("User info request error");
    }
  };

  return (
    <>
      <SetupPassword
        title={t("pageHeader.setupPassword")}
        continueText={t("buttons.continue")}
        goBackText={t("buttons.goBack")}
        onContinue={handleContinueClick}
        onGoBack={handleGoBack}
      />
      <SetUserPasswordDialogs
        {...dialogInfo}
        closeDialog={handleCloseDialog}
        isDialogOpen={isDialogOpen}
        openDialog={openDialog}
      />
    </>
  );
};
