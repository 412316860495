import { validateNationalId } from "@modules/Registration/SetUserData/SetUserData.validators";
import { composeValidators, phoneValidator } from "@utils/services/Validation";

import { PHONE_NOT_VALID_ERROR, FORM_FIELDS } from "./forgotPassword.constants";

const isPhoneValid = phoneValidator(PHONE_NOT_VALID_ERROR);
const validatePhone = composeValidators(isPhoneValid);

export const forgotPasswordValidators = {
  [FORM_FIELDS.MOBILE_PHONE]: validatePhone,
  [FORM_FIELDS.NATIONAL_ID]: validateNationalId,
};
