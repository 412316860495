import { Box, styled } from "@mui/material";
import { COLORS, Typography } from "@ui-kit";
import { FC } from "react";

import LogoAr from "@assets/uiImages/JAKArLogo.svg";
import Logo from "@assets/uiImages/JAKLogo.svg";
import { languages, languageService } from "@store/languageService";

const HeaderContainer = styled(Box)(() => ({
  background: COLORS.GREY_02,
  border: `4px solid ${COLORS.WHITE}`,
  borderRadius: "16px",
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const LogoIcon = styled("img")(() => ({
  // width: "40px",
  height: "34px",
}));

interface IMobileBaseHeader {
  headerText: string;
}

export const BaseMobileHeader: FC<IMobileBaseHeader> = ({ headerText }) => {
  return (
    <HeaderContainer>
      <LogoIcon src={languageService.getValue().languageCode == languages.AR ? LogoAr : Logo} />
      <Typography variant="p2" paddingTop="12px">
        {headerText}
      </Typography>
    </HeaderContainer>
  );
};
