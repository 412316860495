import { COMMON_REQUEST_DIALOGS } from "@appTypes/dialogs";
import { EMPLOYMENT_SECTORS } from "@constants/employmentsSectors";
import { OK as HTTP_OK } from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";

import {
  governmentSectorVerificationFailedDialog,
  privateSectorVerificationFailedDialog,
} from "../EmploymentDetails/EmploymentDetails.constants";
import { EMPLOYMENT_DETAILS_DIALOGS } from "../EmploymentDetails/EmploymentDetails.types";
import { IEmploymentDetailsResult } from "../EmploymentSector.types";

export const getSectorVerificationResult = (
  status: number,
  result: boolean,
  sector: EMPLOYMENT_SECTORS,
): IEmploymentDetailsResult => {
  if (status === HTTP_OK) {
    if (result) {
      return {
        isSuccess: true,
      };
    } else {
      const isPrivateSector = sector === EMPLOYMENT_SECTORS.PRIVATE;

      return {
        isSuccess: false,
        dialogType: isPrivateSector
          ? EMPLOYMENT_DETAILS_DIALOGS.PRIVATE_SECTOR_NOT_VERIFIED
          : EMPLOYMENT_DETAILS_DIALOGS.PRIVATE_SECTOR_NOT_VERIFIED,
        dialog: isPrivateSector ? privateSectorVerificationFailedDialog : governmentSectorVerificationFailedDialog,
      };
    }
  }

  return {
    isSuccess: false,
    dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
    dialog: dialogContents.somethingWentWrong,
  };
};
