import { Box } from "@mui/material";
import { Typography } from "@ui-kit";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { FC, memo, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { AnimatedLoader } from "@components/Loader";
import { convertDigitsToArabic } from "@utils/services/Text/Text.service";

import { useContract } from "../hooks/useContract";
import { useContractKey } from "../hooks/useContractKey";
import { useContractResize } from "../hooks/useContractResize";

interface IContract {
  onLoadingError(): void;
  onLoadingSuccess(): void;
}

export const Contract: FC<IContract> = memo(({ onLoadingError, onLoadingSuccess }) => {
  const [numPages, setNumPages] = useState(0);
  const [translatedFileName, setContractFileName] = useState("");
  const { t, i18n } = useTranslation();

  const { contractKey, isContractKeyError } = useContractKey();
  const { contract, fileName, isContractLoadingError } = useContract(contractKey);
  const { pageWidth, containerRef } = useContractResize();

  useEffect(() => {
    const contractNumber = fileName.split("no.")[1];
    const convertedNumber = i18n.language === "en" ? contractNumber : convertDigitsToArabic(contractNumber);
    const translatedFileName =
      i18n.language === "en" ? fileName : t("checkout.title.contractName", { contractNumber: convertedNumber });

    setContractFileName(translatedFileName);
  }, [fileName, i18n.language]);

  useEffect(() => {
    if (isContractKeyError || isContractLoadingError) {
      onLoadingError();
    }
  }, [isContractLoadingError, isContractKeyError]);

  const onDocumentLoadSuccess = useCallback((params: PDFDocumentProxy) => {
    onLoadingSuccess();
    setNumPages(params.numPages);
  }, []);

  return (
    <>
      {contract && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          ref={containerRef}
        >
          <Typography variant="h5" fontWeight="bold">
            {translatedFileName}
          </Typography>
          <Typography>{t("checkout.content.reviewContract")}</Typography>
          <Document
            file={{ data: contract }}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <Box mt="40px">
                <AnimatedLoader />
              </Box>
            }
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} width={pageWidth} pageNumber={index + 1} />
            ))}
          </Document>
        </Box>
      )}
    </>
  );
});

Contract.displayName = "Contract";
