import { Typography, styled, Box, Grid } from "@mui/material";
import { COLORS } from "@ui-kit";

export const RequestAmountTxt = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {},
}));

export const AmountTxt = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: COLORS.PRIMARY_DARK,
  padding: "0px 20px",
  [theme.breakpoints.down("sm")]: {},
}));

export const CenterContainer = styled(Grid)(({ theme }) => ({
  margin: "4% 12%",
  [theme.breakpoints.down("sm")]: {
    margin: "4% 0",
  },
}));

export const MainBox = styled(Box)(({ theme }) => ({
  border: "2px solid #E9EBFC",
  padding: "30px 20px",
  borderRadius: 10,
  [theme.breakpoints.down("sm")]: {},
}));

export const BoxRow = styled(Grid)(({ theme }) => ({
  display: "flex",
  borderBottom: "1px solid #EDEEEF",
  paddingBottom: 20,
  marginBottom: 20,
  justifyContent: "space-between",
  textAlign: "start",
  [theme.breakpoints.down("sm")]: {},
}));

export const BoxLastRow = styled(Grid)(({ theme }) => ({
  display: "flex",
  borderBottom: "1px solid #EDEEEF",
  paddingBottom: 0,
  marginBottom: 20,
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {},
}));

export const RequestedAmount = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: "#828282",
  fontSize: "12px",
  [theme.breakpoints.down("sm")]: {},
}));

export const RequestedAmountValue = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  color: "#F5B335",
  fontSize: "30px",
  paddingTop: "5px",
  [theme.breakpoints.down("sm")]: {},
}));

export const TotalAmount = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#000",
  fontSize: "14px",
  paddingTop: "5px",
  [theme.breakpoints.down("sm")]: {},
}));

export const APRAmount = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  color: "#000",
  fontSize: "14px",
  paddingTop: "30px",
  [theme.breakpoints.down("sm")]: {},
}));
