import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, styled } from "@mui/material";
import { COLORS } from "@ui-kit";
import { ChangeEvent, FC } from "react";
export type TTextValue = {
  text: string;
  value: string;
};

export type TStyledRadioButtonGroupProps = {
  groupId: string;
  groupTitle: string;
  // eslint-disable-next-line
  value: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  textValues: TTextValue[];
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  border: "1px solid #DFE2E6",
  paddingTop: "5px",
  paddingBottom: "5px",
  paddingLeft: theme.direction == "rtl" ? "20px" : "10x",
  paddingRight: theme.direction == "ltr" ? "20px" : "10px",
  borderRadius: "10px",
  marginTop: "1px",
  marginLeft: theme.direction == "rtl" ? "10px" : "0px",
  marginRight: theme.direction == "ltr" ? "10px" : "0px",
  [theme.breakpoints.up("sm")]: {
    width: "38%",
  },
}));

const StyledRadio = styled(Radio)(() => ({
  color: "#71777D",

  "&.Mui-checked": {
    color: COLORS.PRIMARY_MAIN,
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  fontSize: 14,
  paddingBottom: 8,
  lineHeight: "1.4375em",
  fontWeight: "bold",
  color: "#6B727C",
  "&, &.Mui-focused": {
    color: "#6B727C",
  },
}));

const StyledRadioButtonGroup: FC<TStyledRadioButtonGroupProps> = ({
  groupId,
  groupTitle,
  value,
  onChange,
  textValues,
}) => {
  return (
    <FormControl style={{ minWidth: "100%" }}>
      <StyledFormLabel id={groupId}>{groupTitle}</StyledFormLabel>
      <RadioGroup
        value={value}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={onChange}
      >
        {textValues.map((item) => {
          return (
            <StyledFormControlLabel
              key={`${groupId}_${item.value}`}
              value={item.value}
              control={<StyledRadio />}
              label={item.text}
              style={{
                background: item.value == value ? "rgba(246,246,247,255)" : "none",
                border: item.value == value ? "1px solid #201747" : "1px solid #DFE2E6",
              }}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default StyledRadioButtonGroup;
