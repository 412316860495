import { COMMON_REQUEST_DIALOGS, IDialogWithHandler, IDialogComponentProps } from "@appTypes/dialogs";
import { ITimeObject } from "@utils/services/Timer/timer.types";

export type FormFields = {
  NATIONAL_ID: string;
  PASSWORD: string;
};

export type FormErrors = {
  NATIONAL_ID: string[];
  PASSWORD: string[];
};

export interface IDialogOptions {
  blockingTimer?: ITimeObject<number> | null;
  blockedUntil?: string;
  blockTimer?: string;
}

export enum LOGIN_DIALOGS {
  USER_BLOCKED = "USER_BLOCKED",
  USER_TEMP_BLOCKED = "USER_TEMP_BLOCKED",
  USER_WRONG_CREEDS = "USER_WRONG_CREEDS",
  USER_LOGIN_ATTEMPTS_BLOCKED = "USER_LOGIN_ATTEMPTS_BLOCKED",
}

export type loginDialogs = COMMON_REQUEST_DIALOGS | LOGIN_DIALOGS;

export type dialogParameters = IDialogWithHandler<loginDialogs, IDialogOptions>;

export type ILoginDialogs = IDialogComponentProps<loginDialogs, IDialogOptions>;
