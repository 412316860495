import { FC, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";

import { IDialogContents } from "@appTypes/dialogs";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { POPUP_EVENTS, POPUP_TYPES } from "@constants/popups";
import { popupService } from "@store/popups/popups";
import { usePopupData, usePopupOptions, usePopupStatus } from "@store/popups/popups.hooks";
import { IPopupOptions, IPopupStatus } from "@store/popups/popups.types";
import { useDialog } from "@utils/hooks/useDialog";

import { popupEventEmitter } from "./PopupEventEmitter";
import { DialogContentSwitcher } from "./components/DialogContentSwitcher";

export const PopupProvider: FC = memo(({ children }) => {
  const { t } = useTranslation();

  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [dialog, setDialog] = useState<IDialogContents>();
  const [dialogType, setDialogType] = useState(POPUP_TYPES.NOT_SELECTED);
  const [popupOptions, setPopupOptions] = useState<IPopupOptions>({});
  const [isBlockingPopup, setCloseFlag] = useState(false);

  usePopupStatus((popupStatus: IPopupStatus) => {
    if (popupStatus.isOpen) {
      openDialog();
      setDialogType(popupStatus.dialogType);
      setCloseFlag(!!popupStatus.isBlockingPopup);
    } else {
      setDialogType(POPUP_TYPES.NOT_SELECTED);
      closeDialog();
    }
  });
  usePopupData(setDialog);
  usePopupOptions(setPopupOptions);

  const handleAgreeDialogClick = () => popupEventEmitter.emit(POPUP_EVENTS.AGREE);

  const handleCancelDialogClick = () => popupEventEmitter.emit(POPUP_EVENTS.CANCEL);

  const closeDialogEvent = () => popupService.setPopupStatus({ isOpen: false, dialogType: POPUP_TYPES.NOT_SELECTED });

  const handleCloseDialog = useCallback(() => {
    isBlockingPopup ? noop() : closeDialogEvent();
  }, [isBlockingPopup]);

  return (
    <>
      {children}
      <BasicDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onAgree={handleAgreeDialogClick}
        onCancel={handleCancelDialogClick}
        title={t(dialog?.title as string)}
        description={
          <DialogContentSwitcher
            dialogType={dialogType}
            description={dialog?.description || ""}
            closeDialog={closeDialogEvent}
            dialogOptions={popupOptions}
          />
        }
        icon={dialog?.icon}
        cancelButtonText={dialog?.cancelText ? t(dialog.cancelText as string) : ""}
        agreeButtonText={dialog?.agreeText ? t(dialog.agreeText as string) : ""}
      />
    </>
  );
});

PopupProvider.displayName = "PopupProvider";
