import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Button, ButtonProps, styled } from "@mui/material";

import { COLORS } from "../../constants/enums/COLORS";

interface IIconsArrayElement {
  icon: ReactJSXElement;
  label: string;
  key?: string;
}

interface IIconNavigationProps {
  iconsArray: IIconsArrayElement[];
  selectedValue: string;
  onClick: (value: string) => void;
}

interface INavButtonProps extends ButtonProps {
  selected: boolean;
}

const Navigation = styled("nav")(({ theme }) => ({
  paddingBottom: "8px",
  backgroundColor: theme.palette?.greyscale?.white || COLORS.WHITE,
}));

const ListContainer = styled("ul")(({ theme }) => ({
  paddingLeft: 0,
  display: "flex",
  overflowX: "scroll",
  margin: 0,
  "::-webkit-scrollbar": {
    height: "5px",
  },
  "::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: theme.palette?.greyscale?.grey64 || COLORS.GREY_64,
    borderRadius: "100px",
  },
}));

const NavButton = styled(Button)<INavButtonProps>(({ theme, selected }) => ({
  height: "76px",
  minWidth: "93px",
  display: "flex",
  flexDirection: "column",
  padding: "0",
  borderRadius: "0",
  color: theme.palette?.greyscale?.grey64 || COLORS.GREY_64,
  backgroundColor: theme.palette?.greyscale?.white || COLORS.WHITE,
  "& .MuiButton-startIcon": {
    marginRight: "0",
    marginBottom: "5px",
  },
  "&:hover": {
    color: theme.palette?.secondary?.main || COLORS.SECONDARY_MAIN,
    backgroundColor: theme.palette?.greyscale?.white || COLORS.WHITE,
    "& .MuiButton-startIcon svg path": {
      stroke: theme.palette?.secondary?.main || COLORS.SECONDARY_MAIN,
    },
  },
  ...(selected && {
    color: theme.palette?.secondary?.main || COLORS.SECONDARY_MAIN,
    "& .MuiButton-startIcon svg path": {
      stroke: theme.palette?.secondary?.main || COLORS.SECONDARY_MAIN,
    },
  }),
}));

export const IconNavigation: React.FC<IIconNavigationProps> = ({ iconsArray, onClick, selectedValue }) => {
  return (
    <Navigation>
      <ListContainer>
        {!!iconsArray?.length &&
          iconsArray.map(({ icon, label }) => (
            <NavButton
              selected={selectedValue === label}
              onClick={() => onClick(label)}
              key={label}
              startIcon={icon}
            >
              {label}
            </NavButton>
          ))}
      </ListContainer>
    </Navigation>
  );
};
