import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Stepper } from "@ui-kit";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import rtlPlugin from "stylis-plugin-rtl";

interface IStepperProps {
  steps: string[];
  activeStep?: number;
}

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [
    rtlPlugin as (element: unknown, index: unknown, children: unknown, callback: unknown) => string | undefined,
  ],
});

const cacheLtr = createCache({
  key: "muiltr",
  stylisPlugins: [],
});

//TODO: If cache will be added on root App level, this component will be redundant

export const StepperArabic: FC<IStepperProps> = (stepperProps) => {
  const [chosenCache, setChosenCache] = useState(cacheLtr);
  const { i18n } = useTranslation();

  useEffect(() => {
    setChosenCache(i18n.language === "en" ? cacheLtr : cacheRtl);
  }, [i18n.language]);

  return (
    <CacheProvider value={chosenCache}>
      <Stepper {...stepperProps} />
    </CacheProvider>
  );
};
