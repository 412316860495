import { DateObject } from "react-multi-date-picker";

import { COMMON_REQUEST_DIALOGS } from "@appTypes/dialogs";

export type FormFields = {
  BIRTH_DATE: DateObject | Date | string;
};

export type FormErrors = {
  BIRTH_DATE: string[];
};

export enum UPDATE_DOB_RESPONSE_STATUSES {
  SUCCESS = "SUCCESS",
  TEMP_BLOCKED = "TEMP_BLOCKED",
  FAILURE = "FAILURE",
}

export enum UPDATE_DOB_DIALOGS {
  TEMP_BLOCKED = "TEMP_BLOCKED",
  FAILURE = "FAILURE",
}

export type UpdateDOBDialogTypes = COMMON_REQUEST_DIALOGS | UPDATE_DOB_DIALOGS;

export const DIALOG_TYPES = { ...COMMON_REQUEST_DIALOGS, ...UPDATE_DOB_DIALOGS };
