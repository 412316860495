import { COMMON_REQUEST_DIALOGS, IDialogInfo } from "@appTypes/dialogs";
import { IKYCVerificationResponse } from "@appTypes/responses";
import { OK as HTTP_OK } from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups/popups.content";
import { IAxiosResponseWithError, network } from "@utils/network/network";

import { somethingWentWrongDialog } from "../SetKYC.constants";
import { KYC_GENERATION_DIALOGS, KYC_REQUEST_STATUSES } from "../SetKYC.types";

export interface IRequestResult
  extends IDialogInfo<
    KYC_GENERATION_DIALOGS | COMMON_REQUEST_DIALOGS,
    {
      blockedUntil?: string;
      requestAt?: string;
    }
  > {
  isSuccess: boolean;
}

type useGetOTPStatusReturnType = { generateKYCCode: () => Promise<IRequestResult> };

export const useGenerateKYCCode = (): useGetOTPStatusReturnType => {
  const generateKYCCodeRequest = (): Promise<IAxiosResponseWithError<IKYCVerificationResponse>> => {
    return network.request<IKYCVerificationResponse>("connect", "kyc/otp/generation", {
      method: "POST",
      withCredentials: process.env.REACT_APP_WITH_MOCK_SERVER !== "true",
    });
  };

  const generateKYCCode = async (): Promise<IRequestResult> => {
    const { data, status } = await generateKYCCodeRequest();

    if (status === HTTP_OK) {
      switch (data.result) {
      case KYC_REQUEST_STATUSES.SUCCESS:
        return {
          isSuccess: true,
          dialogOptions: {
            requestAt: data.requestAt,
          },
        };

      case KYC_REQUEST_STATUSES.TEMP_BLOCKED:
        return {
          isSuccess: false,
          dialogType: KYC_GENERATION_DIALOGS.USER_TEMP_BLOCKED,
          dialog: dialogContents.userLockedByRegAttempts,
          dialogOptions: {
            blockedUntil: data.blockedUntil,
          },
        };

      case KYC_REQUEST_STATUSES.FAILURE:
      default:
        return somethingWentWrongDialog;
      }
    }

    return somethingWentWrongDialog;
  };

  return { generateKYCCode };
};
