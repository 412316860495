import { styled } from "@mui/material";
import { FC } from "react";

import { Typography } from "../../Typography";

const PluginContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

const MobileHeaderButton = styled("div")(() => ({
  width: "134px",
  height: "5px",
  backgroundColor: "#E8ECF4",
  borderRadius: "3px",
  alignSelf: "center",
  marginTop: "16px",
  marginBottom: "22px",
}));

export const HeaderPlugin: FC<{ title: string; position: string }> = ({ title }) => {
  return (
    <PluginContainer>
      <MobileHeaderButton />
      <Typography
        className="datepicker-title"
        variant="p1"
        fontWeight="bold"
        paragraph
        textAlign="center"
      >
        {title}
      </Typography>
    </PluginContainer>
  );
};
