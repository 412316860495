import { IconButton, Input, InputAdornment, styled, useTheme } from "@mui/material";
import { InputProps } from "@mui/material/Input";
import React from "react";

import { ReactComponent as CrossIcon } from "../../../assets/icons/cross_in_circle.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { COLORS } from "../../../constants/enums/COLORS";

interface ISearchInputProps extends InputProps {
  onClearIconClick: () => void;
}

const SearchIconContainer = styled("div")`
  margin: 0 12px;
  min-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSearchIcon = styled(SearchIcon)`
  width: 16px;
  height: 16px;
`;

const StyledInput = styled(Input)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: theme.palette?.greyscale?.grey02 || COLORS.GREY_02,
  minWidth: "327px",
  height: "32px",
  "&.filled": {
    backgroundColor: theme.palette?.greyscale?.white || COLORS.WHITE,
  },
  "& .MuiInput-input": {
    backgroundColor: theme.palette?.greyscale?.grey02 || COLORS.GREY_02,
    borderRadius: "8px",
    fontWeight: 600,
    fontSize: "1.4rem",
    "::placeholder": {
      color: theme.palette?.greyscale?.grey32 || COLORS.GREY_32,
      fontWeight: 400,
    },
  },
  "&.Mui-focused": {
    color: theme.palette?.secondary?.navy || COLORS.SECONDARY_NAVY,
    backgroundColor: theme.palette?.greyscale?.white || COLORS.WHITE,
    "& .searchIcon": {
      path: {
        stroke: theme.palette?.secondary?.navy || COLORS.SECONDARY_NAVY,
      },
    },
  },
  "&.filled .MuiInput-input": {
    backgroundColor: theme.palette?.greyscale?.white || COLORS.WHITE,
    color: theme.palette?.greyscale?.grey100 || COLORS.GREY_100,
    "& .searchIcon": {
      path: {
        stroke: theme.palette?.greyscale?.grey64 || COLORS.GREY_64,
      },
    },
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: "6px",
  marginRight: theme.direction === "ltr" ? "6px" : "0",
  marginLeft: theme.direction === "ltr" ? "0" : "6px",
}));

const StyledCrossIcon = styled(CrossIcon)`
  width: 16px;
  height: 16px;
`;

export const SearchInput: React.FC<ISearchInputProps> = ({ onClearIconClick, ...props }) => {
  const theme = useTheme();
  return (
    <>
      <StyledInput
        className={props.value ? "filled" : ""}
        startAdornment={
          <SearchIconContainer>
            <StyledSearchIcon className="searchIcon" />
          </SearchIconContainer>
        }
        endAdornment={
          !!props.value && (
            <InputAdornment position={theme.direction === "ltr" ? "end" : "start"}>
              <StyledIconButton onClick={onClearIconClick}>
                <StyledCrossIcon />
              </StyledIconButton>
            </InputAdornment>
          )
        }
        {...props}
      />
    </>
  );
};
