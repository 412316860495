import { ReactComponent as ErrorIcon } from "@assets/icons/popup_error_icon.svg";
import { ReactComponent as InfoIcon } from "@assets/icons/popup_info_icon.svg";
import { DEFAULT_USER_FORM_DATA, USER_FORM_FIELDS } from "@constants/defaults/userData";

import { FormErrors } from "./SetUserData.types";

export enum FORM_ERRORS {
  EMAIL_NOT_VALID_ERROR = "EMAIL_NOT_VALID_ERROR",
  PHONE_NOT_VALID_ERROR = "PHONE_NOT_VALID_ERROR",
  NATIONAL_ID_NOT_VALID_ERROR = "NATIONAL_ID_NOT_VALID_ERROR",
  BIRTH_DATE_UNDERAGE_ERROR = "BIRTH_DATE_UNDERAGE_ERROR",
}

export const DEFAULT_FORM_ERRORS: FormErrors = {
  [USER_FORM_FIELDS.BIRTH_DATE]: [],
  [USER_FORM_FIELDS.EMAIL]: [],
  [USER_FORM_FIELDS.MOBILE_PHONE]: [],
  [USER_FORM_FIELDS.NATIONAL_ID]: [],
};

export const userExistsDialog = {
  title: "dialogTitle.userAlreadyRegistered",
  description: "dialogContent.proceedWithExistingLogin",
  cancelText: "buttons.goBack",
  agreeText: "buttons.logInSingle",
  icon: <InfoIcon />,
};

export const phoneValidationFailed = {
  title: "dialogTitle.somethingWentWrong",
  description: "dialogContent.enterPhoneFromAbsher",
  agreeText: "buttons.tryAgain",
  icon: <ErrorIcon />,
};

export const DEFAULT_REGISTRATION_DATA = {
  form: { ...DEFAULT_USER_FORM_DATA },
};
