import { FormFields, FormErrors } from "./login.types";

export const NATIONAL_ID_NOT_VALID_ERROR = "NATIONAL_ID_NOT_VALID_ERROR";
export const NATIONAL_ID_IS_MANDATORY_ERROR = "NATIONAL_ID_IS_MANDATORY_ERROR";
export const WRONG_PASSWORD_ERROR = "WRONG_PASSWORD_ERROR";

export const SOMETHING_WENT_WRONG_DIALOG = "SOMETHING_WENT_WRONG_DIALOG";
export const USER_BLOCKED_DIALOG = "";

export enum FORM_FIELDS {
  NATIONAL_ID = "NATIONAL_ID",
  PASSWORD = "PASSWORD",
}

export const DEFAULT_FORM_DATA: FormFields = {
  [FORM_FIELDS.NATIONAL_ID]: "",
  [FORM_FIELDS.PASSWORD]: "",
};

export const DEFAULT_FORM_ERRORS: FormErrors = {
  [FORM_FIELDS.NATIONAL_ID]: [],
  [FORM_FIELDS.PASSWORD]: [],
};
