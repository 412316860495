import { IDialogInfo } from "@appTypes/dialogs";
import { OK } from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { languages as LANGUAGES } from "@store/languageService";
import { IAxiosResponseWithError, network } from "@utils/network/network";

import { SUCCESS_DIALOG } from "../WaitingConfirmation.constants";

export enum CALLBACK_DIALOG_TYPES {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}
export interface IRequestCallResponse {
  callbackAvailableAt: string;
  result: string;
}
export interface IRequestCallDialogInfo extends IDialogInfo<CALLBACK_DIALOG_TYPES> {
  isSuccess: boolean;
  data?: IRequestCallResponse;
}

type useCallRequestReturnType = {
  requestCall: (lang: LANGUAGES) => Promise<IRequestCallDialogInfo>;
};

export const useCallRequest = (): useCallRequestReturnType => {
  const fetchRequestCall = (lang: LANGUAGES): Promise<IAxiosResponseWithError<IRequestCallResponse>> => {
    return network.request<IRequestCallResponse>("order", "customer/autocall/callback", {
      method: "POST",
      params: { lang },
    });
  };

  const requestCall = async (lang: LANGUAGES): Promise<IRequestCallDialogInfo> => {
    const { status, data } = await fetchRequestCall(lang);

    switch (status) {
    case OK: {
      return {
        isSuccess: true,
        data,
        dialogType: CALLBACK_DIALOG_TYPES.SUCCESS,
        dialog: SUCCESS_DIALOG,
      };
    }
    default: {
      return {
        isSuccess: false,
        dialogType: CALLBACK_DIALOG_TYPES.FAILURE,
        dialog: dialogContents.somethingWentWrong,
      };
    }
    }
  };

  return { requestCall };
};
