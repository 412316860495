export const WEB_CHECKOUT_EN = {
  labels: {
    loanExpiresIn: "The loan expires in:",
  },
  steps: {
    phoneVerification: "Phone call verification",
    contractSigned: "Contract signed",
    ePromissoryNote: "E-promissory note",
    loanActivated: "Finance activated",
  },
  title: {
    under_maintenance_title: "Sorry for inconvenience!",
    under_maintenance: "Our service is under maintenance and will get back soon to serve you better.",
    greetings: "Hello, {{name}}!",
    waitPhoneCall: "Please wait for our call",
    contractReady: "Your contract is ready!",
    ePromissory: "Approve the e-promissory note",
    loanIsActivated: "Congratulations!",
    orderCanceled: "Unfortunately the order has been cancelled",
    orderRejected: "The contract has been rejected",
    didntMeetQuaraCriteria: "Sorry you did not meet Quara Finance credit criteria",
    contractExpired: "The contract has been expired",
    orderCompleted: "This order has been completed successfully",
    missedPayment: "You have a missed payment",
    downloadWithBenefits: "Download Quara Finance app and get our benefits",
    contractName: "Contract for order no. {{ contractNumber }}",
  },
  content: {
    contractIntroducing:
      "Thank you for choosing Quara Finance to apply for a finance. To complete the finance procedure by signing the contract, click on get started.",
    phoneConfirmation:
      "We will confirm the next steps during our conversation and you’ll be directed to sign the contract.",
    fullAgreementVersion: "You will have a full view of our agreenment before signing it.",
    confirmContract:
      "I assure that all data included in the finance application are correct and up-to-date. In addition, I authorize Quara Finance to send my details to SIMAH for a credit check and whereby by approving all content of the contract, I electronically sign it",
    nafithInstruction:
      "After clicking on link from Nafith SMS you’ll be redirected to Nafith website where you’ll be asked to approve e-promissory note and activate the loan.",
    storeInformedAboutActivation:
      "The finance will be activated in 24 hrs. Download the Quara Finance App to easily manage your finance payments.",
    orderCanceled:
      "You still can buy what you need with us. Please visit our pateners and make the payment with Quara Finance. Fo more support Please contact us at: <0>{{email}}</0>",
    orderRejected:
      "We are sorry that you rejected our loan offer, please see our partners' stores and take advantage of our offer to pay for purchases.",
    didntMeetQuaraCriteria: "Please contact us at: <0>{{email}}</0> to learn more",
    contractExpired:
      "Unfotunately the valid time to sign the contract has passed. See our partners' stores and take advantage of our offer to pay for purchases.",
    orderCompleted:
      "Thanks for paying all order installments. Please visit our parteners to make more orders with Quara Finance.",
    missedPayment: "Please open Quara Finance app to pay for the missed installment",
    downloadWithBenefits: "Track your orders, manage your installments, and view our parteners from one place",
    reviewContract: "Review and sign the contract",
    redirectionMSG: "We will redirect you after {{variable}} seconds ...",
  },
};
