import { Box, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { emptyUserData } from "@constants/authorization";
import * as httpCodes from "@constants/httpStatuses";
import { siteMap } from "@constants/siteMap";
import { authorizationStore } from "@store/authorization";
import { useLogInStatus } from "@store/authorization/authorizationHooks";
import { useLogout } from "@utils/network/useLogout";

interface IAppContainerProps {
  children: React.ReactNode;
}

const StyledContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    height: "100%",
    overflowY: "auto",
  },
}));

export const AppLogOutContainer: React.FC<IAppContainerProps> = ({ children }) => {
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(authorizationStore.isLoggedIn());
  const [logoutUserRequest] = useLogout();

  useLogInStatus(({ isLoggedIn }) => {
    setIsAuthenticated(isLoggedIn);
  });

  useEffect(() => {
    if (!isAuthenticated && authorizationStore.getValue().access_token && authorizationStore.getValue().refresh_token) {
      const onLogOut = async () => {
        const { status } = await logoutUserRequest();

        if (status === httpCodes.NO_CONTENT) {
          authorizationStore.setAuthorization(emptyUserData);
          history.push(siteMap.LogInPage.path);
          // window.sessionStorage.clear();
        } else {
          console.log("Logout hasn't occurred");

          authorizationStore.setAuthorization(emptyUserData);
          history.push(siteMap.LogInPage.path);
          // window.sessionStorage.clear();
        }
      };

      onLogOut();
    }
  }, [isAuthenticated]);

  return <StyledContainer>{children}</StyledContainer>;
};
