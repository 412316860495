import { COLORS, Typography } from "@ui-kit";
import { FC } from "react";
import { Trans } from "react-i18next";

import { fontVariants } from "@constants/types";

interface ITextWithEmail {
  textKey: string;
  email: string;
  typographyVariant?: fontVariants;
  color?: string;
}

export const TextWithEmail: FC<ITextWithEmail> = ({ textKey, email, typographyVariant = "p3", color = "" }) => {
  return (
    <Typography variant={typographyVariant} color={color}>
      <Trans
        i18nKey={textKey}
        defaults={textKey}
        values={{ email }}
        components={[<a style={{ color: COLORS.SECONDARY_MAIN }} key={0} href={`mailto: ${email}`} />]}
      />
    </Typography>
  );
};
