import { styled } from "@mui/material";

import { COLORS } from "../../constants/enums/COLORS";
import { Typography } from "../Typography";

interface IBannerProps extends IComingSoonComponent {
  label: string;
  lang: string;
}

interface IComingSoonComponent {
  marginForEn: number;
  marginForArabic: number;
}

const ComingSoonComponent = styled("div")<IComingSoonComponent>(({ theme, lang, marginForEn, marginForArabic }) => ({
  boxSizing: "border-box",
  width: lang === "en" ? "98px" : "66px",
  height: 0,
  margin: lang === "en" ? `5px -${marginForEn}px auto auto` : `5px auto auto -${marginForArabic}px`,
  textAlign: "center",
  lineHeight: "0px",
  color: theme.palette.secondary.navy,
  borderTop: "12px solid #FFFFFF",
  borderBottom: "12px solid #FFFFFF",
  borderLeft: lang === "en" ? "8px solid transparent" : "none",
  borderRight: lang === "en" ? "none" : "8px solid transparent",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.02)",
}));

export const Banner: React.FC<IBannerProps> = ({
  label = "Coming soon",
  lang = "en",
  marginForEn = 0,
  marginForArabic = 0,
}) => {
  return (
    <ComingSoonComponent lang={lang as string} marginForEn={marginForEn} marginForArabic={marginForArabic}>
      <Typography variant="c1" color={COLORS.SECONDARY_NAVY} fontSize="12px">
        {label}
      </Typography>
    </ComingSoonComponent>
  );
};
