import { FC, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { noop } from "rxjs";

import { IDialogComponentProps } from "@appTypes/dialogs";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { siteMap } from "@constants/siteMap";

import { PERSONAL_DATA_DIALOGS, IDialogOptions, setPasswordDialogs } from "../SetUserPassword.types";
import { DialogContentsSwitcher } from "./DialogContentSwitcher";

type IUserDialogs = IDialogComponentProps<setPasswordDialogs, IDialogOptions>;

export const SetUserPasswordDialogs: FC<IUserDialogs> = memo(
  ({ dialog, dialogType, dialogOptions, isDialogOpen, closeDialog, lastAction = noop, parameters = [] }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const handleAgreeDialogClick = useCallback(() => {
      switch (dialogType) {
      case PERSONAL_DATA_DIALOGS.UNVERIFIED_DATE_OF_BIRTH:
        history.push(siteMap.UpdateDateOfBirth.path);
        break;

      default:
        closeDialog();
        lastAction(...parameters);
        break;
      }
    }, [dialogType, lastAction]);

    return isDialogOpen ? (
      <BasicDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onAgree={handleAgreeDialogClick}
        title={t(dialog?.title as string)}
        description={
          <DialogContentsSwitcher
            dialogType={dialogType}
            description={dialog?.description}
            dialogOptions={dialogOptions}
          />
        }
        icon={dialog?.icon}
        cancelButtonText={dialog?.cancelText ? t(dialog.cancelText as string) : ""}
        agreeButtonText={dialog?.agreeText ? t(dialog.agreeText as string) : ""}
      />
    ) : null;
  },
);

SetUserPasswordDialogs.displayName = "SetUserPasswordDialogs";
