import { ButtonGroup, styled } from "@mui/material";

import { COLORS } from "../../constants/enums/COLORS";
import { Button } from "../Button";

interface ISingleButtonGroupButton {
  label: string;
  key?: string;
}

interface IButtonGroupSelectProps {
  buttonsArray: Array<ISingleButtonGroupButton>;
  chosenValue: string;
  onClick: (value: string) => void;
}

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: theme.palette?.greyscale?.grey04 || COLORS.GREY_04,
  "& .MuiButton-root.MuiButtonGroup-grouped": {
    borderRadius: "8px",
  },
  "& .MuiButtonGroup-grouped:not(:last-of-type)": {
    borderRight: "unset",
  },
}));

export const ButtonGroupSelect: React.FC<IButtonGroupSelectProps> = ({ buttonsArray, onClick, chosenValue }) => {
  return (
    <StyledButtonGroup variant="contained">
      {!!buttonsArray?.length &&
        buttonsArray.map(({ label, key }) => (
          <Button
            key={key || label}
            color={chosenValue === label ? "primary" : "secondary"}
            sx={
              chosenValue === label
                ? { color: COLORS.WHITE }
                : { "&.MuiButton-containedSecondary": { color: COLORS.GREY_64 } }
            }
            onClick={() => onClick(label)}
          >
            {label}
          </Button>
        ))}
    </StyledButtonGroup>
  );
};
