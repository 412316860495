import { futuresList } from "@constants/featureToggles";

import { IFeature } from "./Features.types";
import { isActiveFeature } from "./Futures.utils";

export interface IFeatureService {
  features: IFeature[];
  activeFeatures: string[];
}

class FeatureService implements IFeatureService {
  private _activeFeatures;

  constructor(private _futures: IFeature[]) {
    this._activeFeatures = _futures
      .filter((feature: IFeature) => feature.isActive)
      .map((feature: IFeature) => feature.name);
  }

  get features() {
    return this._futures;
  }

  get activeFeatures() {
    return this._activeFeatures;
  }

  isActiveFeature(feature: string) {
    return isActiveFeature(this.activeFeatures, feature);
  }
}

export const featureService = new FeatureService(futuresList);
