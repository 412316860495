import { Box, styled, useMediaQuery, useTheme } from "@mui/material";

import { ReactComponent as WarningIcon } from "../../assets/icons/warning.svg";
import { COLORS } from "../../constants/enums/COLORS";
import { Typography } from "../Typography";

const StyledWarningWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "16px 1fr",
  borderRadius: "8px",
  border: `1px solid ${COLORS.DARK_CRITICAL}`,
  background: COLORS.LIGHT_CRITICAL,
  padding: "12px",
  gap: "10px",
  alignItems: "center",
});

interface IWarningBlockProps {
  warningText: string;
  mb?: string;
  mt?: string;
}

export const WarningBlock: React.FC<IWarningBlockProps> = ({ warningText, mb, mt }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isRtl = theme.direction === "rtl";

  return (
    <StyledWarningWrapper mb={mb} mt={mt}>
      <WarningIcon />
      <Typography
        color={COLORS.DARK_CRITICAL}
        fontSize={isMobile ? "1.2rem" : "1.6rem"}
        fontWeight={isRtl ? 600 : "bold"}
        maxWidth="708px"
        lineHeight={isMobile ? "20px" : "28px"}
        component="p"
      >
        {warningText}
      </Typography>
    </StyledWarningWrapper>
  );
};
