import { Box } from "@mui/material";
import { Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";

import { COMMON_REQUEST_DIALOGS } from "@appTypes/dialogs";
import { CountdownTimer } from "@components/CountdownTimer";
import { ITimeObject } from "@utils/services/Timer/timer.types";

import { kycDialogs, KYC_GENERATION_DIALOGS, KYC_VERIFICATION_DIALOGS } from "../SetKYC.types";

interface IDialogContentSwitcher {
  dialogType?: kycDialogs;
  description?: string | JSX.Element;
  dialogOptions: {
    email?: string;
    phoneNumber?: string;
    nationalId?: string;
    blockingTimer?: ITimeObject<number> | null;
    closeDialog?: () => void;
  };
}

export const DialogContentsSwitcher: FC<IDialogContentSwitcher> = ({ dialogType, description = "", dialogOptions }) => {
  const { t } = useTranslation();

  switch (dialogType) {
  case KYC_GENERATION_DIALOGS.USER_TEMP_BLOCKED:
  case KYC_VERIFICATION_DIALOGS.USER_TEMP_BLOCKED:
    return (
      <>
        <Typography variant="p3" textAlign="center" component="p">
          {description ? t(description as string) : ""}
        </Typography>
        {dialogOptions.blockingTimer && (
          <Box mt="8px" display="flex" justifyContent="center">
            <Typography
              variant="p3"
              textAlign="center"
              component="p"
              mr="5px"
              ml="5px"
            >
              {t("labels.retryIn")}
            </Typography>
            <CountdownTimer
              minutes={dialogOptions.blockingTimer.minutes}
              seconds={dialogOptions.blockingTimer.seconds}
              hours={dialogOptions.blockingTimer.hours}
              onTimeoutCallback={dialogOptions.closeDialog || noop}
            />
          </Box>
        )}
      </>
    );

  case COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG:
  default:
    return (
      <Typography variant="p3" textAlign="center" component="p">
        {description ? t(description as string) : ""}
      </Typography>
    );
  }
};
