import { IOTPInfoResponse } from "@appTypes/responses";
import { IAxiosResponseWithError, network } from "@utils/network/network";

type useGetOTPStatusReturnType = [getOTPCodeStatus: () => Promise<IAxiosResponseWithError<IOTPInfoResponse>>];

export const useGetOTPInfo = (): useGetOTPStatusReturnType => {
  const getOTPCodeStatus = (): Promise<IAxiosResponseWithError<IOTPInfoResponse>> => {
    return network.request<IOTPInfoResponse>("connect", "otp/info", {
      method: "GET",
    });
  };

  return [getOTPCodeStatus];
};
