import { Grid, styled } from "@mui/material";

export const PreErrorText = styled("p")(({ theme }) => ({
  color: "#000",
  fontFamily: theme.direction === "ltr" ? "'Inter', sans-serif" : "Cairo",
  fontWeight: "bold",
  fontSize: "110px",
  lineHeight: "157px",
  letterSpacing: "-0.02rem",
  textAlign: "center",
  marginTop: 0,
  marginBottom: "16px",
}));

export const ContentWrapper = styled(Grid)(({ theme }) => ({
  width: "85%",
  margin: "0 auto",
  minHeight: "calc(100vh - 80px)",
  flexDirection: "row",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: "55px",
  },
}));

export const ImgContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ">img": {
    maxWidth: "618px",
    maxHeight: "618px",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    ">img": {
      maxWidth: "220px",
    },
  },
}));
