import { useTheme } from "@mui/material";
import { Typography } from "@ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { fontVariants } from "@constants/types";
import { languages } from "@store/languageService";
import { convertDigitsToArabic } from "@utils/services/Text/Text.service";
import { convertSecondsToMinAndSec } from "@utils/services/Timer/Timer.service";
import { ITimeObject } from "@utils/services/Timer/timer.types";

interface ICountdownTimerProps extends ITimeObject<number> {
  typographyVariant?: fontVariants;
  setTimer?: React.Dispatch<React.SetStateAction<ITimeObject<number> | null>>;
  onTimeoutCallback?: () => void;
}

export const CountdownTimer: React.FC<ICountdownTimerProps> = ({
  minutes,
  seconds,
  hours,
  typographyVariant = "p3",
  setTimer,
  onTimeoutCallback,
}) => {
  const { i18n } = useTranslation();
  const [counter, setCounter] = useState<number>(hours * 60 * 60 + minutes * 60 + seconds);
  const theme = useTheme();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (counter > 0) {
      timer = setInterval(() => setCounter(counter - 1), 1000);
    } else {
      setTimer && setTimer(null);
      onTimeoutCallback && onTimeoutCallback();
    }
    return () => clearInterval(timer);
  }, [counter]);

  const timerObject = convertSecondsToMinAndSec(counter);
  const translatedTimer = Object.keys(timerObject).reduce((timer: Record<string, string>, timerKey: string) => {
    return {
      ...timer,
      [timerKey]: convertDigitsToArabic(
        timerObject[timerKey as keyof ITimeObject<string>],
        i18n.language === languages.AR,
      ),
    };
  }, {});

  return (
    <Typography variant={typographyVariant} color="secondary" fontWeight={theme.direction === "rtl" ? 600 : "bold"}>
      {`${hours !== 0 ? `${translatedTimer.hours}:` : ""}${translatedTimer.minutes}:${translatedTimer.seconds}`}
    </Typography>
  );
};
