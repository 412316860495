import { useEffect } from "react";

import { ILanguage, languageService } from "./language";
import { languages } from "./language.constants";

export const useLanguageCode = (setSelectedLanguage: (langCode?: languages) => void): void => {
  useEffect(() => {
    const languageSubscription = languageService
      .getLanguage()
      .subscribe((langStore: ILanguage) => setSelectedLanguage(langStore.languageCode));

    return () => languageSubscription.unsubscribe();
  }, []);
};
