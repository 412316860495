/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Validate string with Luhn algorithm
 * @param value Accept only digits, dashes or spaces
 * @returns boolean - is value valid
 */
const isLuhnAlgorithmMach = (value: string) => {
  let nCheck = 0;
  let bEven = false;
  value = value.replace(/\D/g, "");

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 == 0;
};

const isExactLength = (value: string, length: number) => value.length === length;
const containsOnlyNumbers = (value: string) => /^[0-9-\s]+$/.test(value);
const startsWith = (value: string, values: string[]) => values.some((v) => value.startsWith(v));

export const hasEmptyFields = (form: Record<string, unknown>): boolean => {
  return Object.values(form).filter((val: unknown) => val === "").length > 0;
};

const isEmailValid = (val: string): boolean =>
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)(?:([\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|\\([\x01-\x09\x0b\x0c\x0e-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\x20|\x09|\x0d|\x0a))*(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]{2,}|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,})|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
    val,
  );

const isPhoneValid = (val: string): boolean =>
  (val.startsWith("05") && val.length === 10) || (val.startsWith("5") && val.length === 9);

const isMoreThan = (val: string, minValue: number) => Number(val) > minValue;

/**
 * Function takes validators array and returns function, which takes form-field value and test it through validators list,
 * if value not valid for specific validator, error adds to errors array.
 * @param validators - validators array
 * @returns errors - errors array
 */
export const composeValidators =
  (...validators: Array<(value: any) => string>) =>
    (value: unknown): string[] =>
      validators.reduce((errors: string[], validator) => {
        const error = validator(value);

        return error ? errors.concat(error) : errors;
      }, []);

export const emailValidator =
  (errorMessage: string) =>
    (value: string): string =>
      isEmailValid(value) ? "" : errorMessage;

export const emptyValueValidator =
  (errorMessage: string) =>
    (value: string): string =>
      value ? "" : errorMessage;

export const phoneValidator =
  (errorMessage: string) =>
    (value: string): string =>
      isPhoneValid(value) ? "" : errorMessage;

export const luhnValidator =
  (errorMessage: string) =>
    (value: string): string =>
      isLuhnAlgorithmMach(value) ? "" : errorMessage;

export const lengthValidator =
  (errorMessage: string) =>
    (length: number) =>
      (value: string): string =>
        isExactLength(value, length) ? "" : errorMessage;

export const containsOnlyNumbersValidator =
  (errorMessage: string) =>
    (value: string): string =>
      containsOnlyNumbers(value) ? "" : errorMessage;

export const startsWithValidator =
  (errorMessage: string) =>
    (values: string[]) =>
      (value: string): string =>
        startsWith(value, values) ? "" : errorMessage;

export const minValueValidator =
  (errorMessage: string) =>
    (minValue: number) =>
      (value: string): string =>
        isMoreThan(value, minValue) ? "" : errorMessage;
