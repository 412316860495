import { Box, styled, useMediaQuery, useTheme } from "@mui/material";

import { DesktopMenu } from "./components/DesktopMenu/DesktopMenu";
import { MobileMenu } from "./components/MobileMenu/MobileMenu";

const MenuWrapper = styled(Box)(({ theme }) => ({
  padding: "16px",
  background: `linear-gradient(90.18deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%)`,
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.up("sm")]: {
    padding: "0px",
    background: `linear-gradient(90.18deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%)`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "20%",
  },
}));

const MenuContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  minHeight: "40px",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.up("sm")]: {
    minHeight: "32px",
    padding: "24px 64px",
  },
}));

export const TopMenu: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MenuWrapper>
      <MenuContainer>{isMobile ? <MobileMenu /> : <DesktopMenu />}</MenuContainer>
    </MenuWrapper>
  );
};
