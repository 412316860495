import { IMenuAdditionalInfo, IMenuInfo } from "./menu.types";

export const defaultMenuData: IMenuInfo = {
  leftSide: "",
  rightSide: "",
  needIcon: false,
};
export const defaultAdditionalContent: IMenuAdditionalInfo = {
  isAdditionalContentNeeded: false,
  additionalContentCode: "",
};
