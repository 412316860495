import { AxiosResponse } from "axios";

import { COMMON_REQUEST_DIALOGS, IDialogInfo } from "@appTypes/dialogs";
import { CREATED as HTTP_CREATED } from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups/popups.content";
import { IAuthorization } from "@store/authorization/authorization";
import { network } from "@utils/network/network";

import { IDialogOptions } from "../SetUserPassword.types";

export interface IRequestResult extends IDialogInfo<COMMON_REQUEST_DIALOGS, IDialogOptions> {
  isSuccess: boolean;
  token?: IAuthorization;
}

type useRequestType = {
  createUser: (passwordInputValue: string) => Promise<IRequestResult>;
};

export const useCreateUser = (): useRequestType => {
  const createUserRequest = (passwordInputValue: string): Promise<AxiosResponse<IAuthorization>> => {
    return network.request<IAuthorization>("connect", "customer/creation", {
      method: "POST",
      withCredentials: process.env.REACT_APP_WITH_MOCK_SERVER !== "true",
      data: {
        password: passwordInputValue,
      },
    });
  };

  const createUser = async (passwordInputValue: string): Promise<IRequestResult> => {
    const { data, status } = await createUserRequest(passwordInputValue);

    if (status === HTTP_CREATED) {
      return {
        isSuccess: true,
        token: data,
      };
    }

    return {
      isSuccess: false,
      dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
      dialog: dialogContents.somethingWentWrong,
    };
  };

  return { createUser };
};
