import { Grid, styled } from "@mui/material";
import { Button, PasswordInput } from "@ui-kit";
import { Link } from "react-router-dom";

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingTop: "0 !important",
  },
}));

export const StyledLoginButton = styled(Button)(({ theme }) => ({
  width: "100%",
  maxWidth: "376px",
  [theme.breakpoints.up("sm")]: {
    width: "270px",
  },
}));

export const StyledPasswordInput = styled(PasswordInput, {
  shouldForwardProp: (prop) => prop !== "isEmpty",
})<{ isEmpty: boolean }>(({ isEmpty }) => ({
  letterSpacing: isEmpty ? "" : "5px",
}));

// export const StyledPasswordInput = styled(PasswordInput)`
//   letter-spacing: 5px;
// `;

export const StyledForgotPasswordLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  fontSize: "14px",
  fontWeight: 600,
  color: theme.palette.secondary.navy,
  [theme.breakpoints.down("sm")]: {
    marginBottom: "35px",
  },
}));
