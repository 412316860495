import { composeValidators, minValueValidator } from "@utils/services/Validation";

import { BIRTH_DATE_UNDERAGE_ERROR, FORM_FIELDS } from "./UpdateDateOfBirth.constants";

const isAgeCorrect = minValueValidator(BIRTH_DATE_UNDERAGE_ERROR)(21);
const validateBirthDate = composeValidators(isAgeCorrect);

export const registrationInfoValidators = {
  [FORM_FIELDS.BIRTH_DATE]: validateBirthDate,
};
