import { IconButton, IconButtonProps, styled } from "@mui/material";

import { COLORS } from "../../constants/enums/COLORS";

interface StyledIconButtonProps extends IconButtonProps {
  width?: string;
  height?: string;
}

const StyledIconButton = styled(IconButton)<StyledIconButtonProps>(({ theme, width, height }) => ({
  "&.MuiIconButton-root": {
    width: width || "48px",
    height: height || "48px",
    borderRadius: "8px",
    backgroundColor: theme.palette?.greyscale?.grey02 || COLORS.GREY_02,
  },
  "&.MuiIconButton-colorSecondary": {
    backgroundColor: theme.palette?.greyscale?.grey100 || COLORS.GREY_100,
    path: {
      stroke: theme.palette?.greyscale?.white || COLORS.WHITE,
    },
  },
  "&.MuiIconButton-sizeSmall": {
    width: "32px",
    height: "32px",
    svg: {
      transform: "scale(0.8)",
    },
  },
}));

export const CustomIconButton: React.FC<StyledIconButtonProps> = (props) => {
  return <StyledIconButton width={props.width} height={props.height} {...props} />;
};
