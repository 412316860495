import { Box, Grid, styled } from "@mui/material";
import { COLORS } from "@ui-kit";

import { ReactComponent as AppLogo } from "@assets/icons/app_logo.svg";

export const FurtherInfoContainer = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  background: COLORS.LIGHT_TEAL,
  justifyContent: "space-around",
  alignItems: "center",
  gap: "0 15px",
  color: "#A06C00",
  fontSize: "1.6rem",
  lineHeight: "28px",

  "& ul": {
    margin: 0,
    padding: "0 0 0 20px",
  },

  "& ul li::marker": {
    fontSize: "10px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "inherit",
    },
  },
}));

export const Header = styled(Grid)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "nowrap",
    borderRadius: "16px",
    padding: "16px",
  },
}));

export const StyledAppInfoContainer = styled(Grid)(({ theme }) => ({
  padding: "24px 16px 4px 27px",
  justifyContent: "space-between",
  background: theme.palette.greyscale.grey04,
  borderRadius: "20px",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    gap: "25px 0",
    borderTop: "none",
  },
}));

export const StyledWrapper = styled(Grid)(({ theme }) => ({
  alignSelf: "center",
  padding: "23px 0",
  marginRight: "20px",
  marginLeft: "20px",
  [theme.breakpoints.down("md")]: {
    padding: "14px",
  },
}));

export const ImageWrapper = styled(Grid)(({ theme }) => ({
  justifyContent: "flex-end",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
}));

export const AppInfoTextWrapper = styled(Grid)(({ theme }) => ({
  gap: "0 16px",
  flexWrap: "nowrap",
  height: "50%",
  marginTop: 15,
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
}));

export const ResponsiveAppLogo = styled(AppLogo)({
  minWidth: "64px",
  height: "64px",
});
