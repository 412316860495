import { Button, Box, Grid, styled } from "@mui/material";
import { COLORS } from "@ui-kit";

export const Header = styled(Grid)(({ theme }) => ({
  marginBottom: 0,
  [theme.breakpoints.up("sm")]: {
    marginBottom: "40px",
  },
}));

export const LogoIcon = styled("img")(({ theme }) => ({
  // width: "24px",
  margin: "0 8px",
  [theme.breakpoints.up("sm")]: {
    // width: "28px",
  },
}));

export const HeaderText = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const MenuButton = styled(Button)`
  padding: 4px 16px;
  color: ${COLORS.SECONDARY_NAVY};
  border: 2px solid #ffffff;
  font-weight: bold;
  background: ${COLORS.GREY_04};
  text-transform: none;
  font-family: "Cairo";
  font-weight: 600;
  &:hover {
    background: ${COLORS.GREY_04};
  }
`;

export const MenuContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));
