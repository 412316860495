import { styled } from "@mui/material";
import { CheckmarkIcon, COLORS, CrossIcon, Typography } from "@ui-kit";
import { useTranslation } from "react-i18next";

import { TPasswordRequirementReturnType } from "@utils/services/Password/Password.service";

interface IPasswordRequirenment {
  isMobile: boolean;
  requirement: TPasswordRequirementReturnType;
}

const RequirementContainer = styled("div")`
  display: grid;
  grid-template-columns: 20px 1fr;
  width: 280px;
  :not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const StyledCrossIcon = styled(CrossIcon)(({ theme }) => ({
  width: "10px",
  height: "16px",
  [theme.breakpoints.up("sm")]: {
    height: "24px",
  },
  justifySelf: "center",
}));

const StyledCheckmark = styled(CheckmarkIcon)(({ theme }) => ({
  width: "12px",
  height: "16px",
  [theme.breakpoints.up("sm")]: {
    height: "24px",
  },
  path: {
    stroke: theme?.palette?.dark?.positive || COLORS.DARK_POSITIVE,
  },
}));

export const PasswordRequirement: React.FC<IPasswordRequirenment> = ({ isMobile, requirement }) => {
  const { t } = useTranslation();

  return (
    <RequirementContainer key={requirement.message + requirement.options?.numberOfSigns}>
      {requirement.value ? <StyledCheckmark /> : <StyledCrossIcon />}
      <Typography
        variant={isMobile ? "c1" : "p2"}
        sx={{ color: requirement.value ? COLORS.DARK_POSITIVE : COLORS.GREY_64, whiteSpace: "pre-wrap" }}
      >
        {t(`errors.${requirement.message}`, { ...requirement.options })}
      </Typography>
    </RequirementContainer>
  );
};
