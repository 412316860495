import IconButton from "@mui/material/IconButton/IconButton";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import arabicCalendar from "react-date-object/calendars/arabic";
import gregorianCalendar from "react-date-object/calendars/gregorian";
import arabic_ar from "react-date-object/locales/arabic_ar";
import arabic_en from "react-date-object/locales/arabic_en";
import gregorian_ar from "react-date-object/locales/gregorian_ar";
import gregorian_en from "react-date-object/locales/gregorian_en";
import DatePicker, { DateObject } from "react-multi-date-picker";

import { ReactComponent as Calendar } from "../../assets/icons/calendar_blue.svg";
import { ReactComponent as ErrorCalendar } from "../../assets/icons/calendar_error.svg";
import { IAbstractInputProps } from "../Inputs/AbstractInput/AbstractInput";
import { BaseInput } from "../Inputs/BaseInput";
import { ButtonsContainer } from "./compoennts/ButtonsContainer";
import "react-multi-date-picker/styles/layouts/mobile.css";
import { HeaderPlugin } from "./compoennts/HeaderPlugin";
import { COLORS } from "../../constants/enums/COLORS";

type languages = "en" | "ar";

interface ICalendarButtons {
  cancel: string;
  select: string;
}

const BUTTONS: Record<languages, ICalendarButtons> = {
  en: {
    cancel: "Cancel",
    select: "Select",
  },
  ar: {
    cancel: "الغاء",
    select: "اختر",
  },
};

function getLocale(lang: languages, isHijriFlag: boolean) {
  switch (true) {
    case lang === "en" && !isHijriFlag:
      return gregorian_en;
    case lang === "en" && isHijriFlag:
      return arabic_en;
    case lang === "ar" && !isHijriFlag:
      return gregorian_ar;
    case lang === "ar" && isHijriFlag:
      return arabic_ar;
    default:
      return gregorian_en;
  }
}

interface IDatePickerProps {
  lang: languages;
  isHijri: boolean;
  maxDate?: Date;
  dateFormat?: string;
  inputProps?: IAbstractInputProps;
  onChange: (dateValue: DateObject) => void;
  dateValue: DateObject | string;
  calendarTitle: string;
  placeholder?: string;
}

export const CustomDatePicker: FC<IDatePickerProps> = ({
  lang,
  isHijri,
  maxDate = new Date(),
  inputProps,
  onChange,
  dateValue,
  dateFormat = "D/MM/YYYY",
  calendarTitle = "",
  placeholder = "",
}): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isPreDesktop = useMediaQuery("@media (max-width:598px)");
  const datePickerRef = useRef();
  const [shouldCloseCalendar, setCloseFlag] = useState<boolean>(false);
  const [selectingDate, setSelectingDateDate] = useState<DateObject>(new DateObject());
  const [locale, setLocale] = useState(getLocale(lang, isHijri));
  const [convertedDateValue, setConvertedDate] = useState<DateObject | string>(dateValue);

  const { errorText } = inputProps as IAbstractInputProps;

  const handleChange = (chosenDate: DateObject) => {
    setSelectingDateDate(chosenDate);

    isMobile && onChange(chosenDate);
  };

  const handleCalendarClose = (): boolean => shouldCloseCalendar;
  const handleCloseMobile = (): boolean => true;
  const handleOpenDatePicker = () => setCloseFlag(false);
  const handleSelectClick = () => {
    onChange(selectingDate);
    setCloseFlag(true);
  };
  const handleCloseClick = () => {
    setCloseFlag(true);
  };

  useEffect(() => {
    setLocale(getLocale(lang, isHijri));
    typeof convertedDateValue !== "string" && onChange(convertedDateValue);
  }, [lang, isHijri]);
  useEffect(() => {
    dateValue && setConvertedDate(new DateObject(dateValue));
  }, [dateValue]);

  useEffect(() => {
    if (datePickerRef?.current && shouldCloseCalendar) {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      (datePickerRef.current as any).closeCalendar();
    }
  }, [shouldCloseCalendar]);

  useLayoutEffect(() => {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    if (datePickerRef?.current && (datePickerRef.current as any).isOpen) {
      (datePickerRef.current as any).closeCalendar();

      if ((datePickerRef.current as any).getElementsByClassName) {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const mobileContainer = (datePickerRef.current as any).getElementsByClassName(
          "rmdp-calendar-container-mobile",
        )[0];

        mobileContainer?.classList && mobileContainer?.classList.remove("rmdp-calendar-container-mobile");
      }
    }
  }, [isPreDesktop]);

  const calendarClasses = useMemo(() => {
    return `quara-picker ${isMobile ? "rmdp-mobile" : ""}`;
  }, [isMobile]);

  return (
    <DatePicker
      ref={datePickerRef}
      plugins={isMobile ? [<HeaderPlugin key="header-plugin" position="top" title={calendarTitle} />] : []}
      className={calendarClasses}
      onOpen={handleOpenDatePicker}
      onClose={isMobile ? handleCloseMobile : handleCalendarClose}
      value={convertedDateValue}
      onChange={handleChange}
      calendar={isHijri ? arabicCalendar : gregorianCalendar}
      locale={locale}
      maxDate={maxDate}
      editable={false}
      arrow={false}
      fixMainPosition
      mobileLabels={{
        OK: BUTTONS[lang].select,
        CANCEL: BUTTONS[lang].cancel,
      }}
      render={(value: unknown, openCalendar: () => void) => (
        <BaseInput
          showAdditionalMessage={false}
          value={typeof convertedDateValue !== "string" ? convertedDateValue.format(dateFormat) : ""}
          placeholder={placeholder}
          endAdornment={
            <InputAdornment position={theme.direction === "ltr" ? "end" : "start"}>
              <IconButton sx={theme.direction === "ltr" ? { right: "-8px" } : { left: "-8px" }} onClick={openCalendar}>
                {errorText ? <ErrorCalendar /> : <Calendar />}
              </IconButton>
            </InputAdornment>
          }
          inputProps={{ disabled: true }}
          {...inputProps}
        />
      )}
    >
      {!isMobile && (
        <ButtonsContainer
          onCancel={handleCloseClick}
          onSelect={handleSelectClick}
          selectText={BUTTONS[lang].select}
          cancelText={BUTTONS[lang].cancel}
        />
      )}
    </DatePicker>
  );
};
