import { styled } from "@mui/material";
import { COLORS, Typography } from "@ui-kit";
import React from "react";
import { Link } from "react-router-dom";

interface ITextWithLink {
  text: string;
  linkText: string;
  linkPath: string;
}

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  fontSize: "14px",
  fontWeight: theme.direction === "ltr" ? 900 : 600,
  color: COLORS.SECONDARY_NAVY,
  [theme.breakpoints.down("sm")]: {
    marginBottom: "35px",
  },
}));

export const TextWithLink: React.FC<ITextWithLink> = ({ text, linkText, linkPath }) => {
  return (
    <Typography
      variant="p2"
      component="p"
      textAlign="center"
      width="100%"
      color={COLORS.GREY_80}
      marginTop="16px"
    >
      {text} <StyledLink to={linkPath}>{linkText}</StyledLink>
    </Typography>
  );
};
