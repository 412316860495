export enum KYC_VALIDATION_STATUSES {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  TEMP_BLOCKED = "TEMP_BLOCKED",
}

export interface IKYCVerificationResponse {
  result: keyof typeof KYC_VALIDATION_STATUSES;
  attempts: number;
  blockedUntil: string;
  requestAt: string;
}
