import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { COMMON_REQUEST_DIALOGS, IDialogWithHandler } from "@appTypes/dialogs";
import { POPUP_EVENTS, POPUP_TYPES } from "@constants/popups";
import { MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { popupService } from "@store/popups/popups";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";
import { popupEventEmitter } from "@utils/services/Popups/PopupEventEmitter";
import { usePopup } from "@utils/services/Popups/hooks/usePopup";

import { PHONE_VALIDATION_DIALOGS, USER_VALIDATION_DIALOGS } from "../SetUserData.types";

type popupCodes = USER_VALIDATION_DIALOGS | PHONE_VALIDATION_DIALOGS | COMMON_REQUEST_DIALOGS;

const dialogsMap: Record<popupCodes, POPUP_TYPES> = {
  [PHONE_VALIDATION_DIALOGS.UNVERIFIED_PHONE_BLOCKED]: POPUP_TYPES.WITH_COUNTDOWN_LABEL,
  [USER_VALIDATION_DIALOGS.UNVERIFIED_ID_AND_PHONE_BLOCKED]: POPUP_TYPES.WITH_COUNTDOWN_LABEL,
  [PHONE_VALIDATION_DIALOGS.WRONG_ATTEMPT]: POPUP_TYPES.WRONG_ATTEMPT,
  [USER_VALIDATION_DIALOGS.USER_EXISTS]: POPUP_TYPES.COMMON,
  [COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG]: POPUP_TYPES.COMMON,
};

type dialogWithParamsType = <T, U>(dialogData: IDialogWithHandler<T, U>) => void;

type dialogHandlerType = { openPopupWithParams: dialogWithParamsType };

export const usePopupHandlers = (): dialogHandlerType => {
  const history = useHistory();
  const { openPopupWithParams, popupCode, handlerParams, lastAction } = usePopup<popupCodes>(dialogsMap);

  const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, {
    checkoutToken: "",
    merchantUrl: "",
    merchantSuccessUrl: "",
    merchantFailureUrl: "",
    merchantCancelUrl: "",
  });

  useEffect(() => {
    popupEventEmitter.on(POPUP_EVENTS.AGREE, () => {
      switch (popupCode) {
      case USER_VALIDATION_DIALOGS.USER_EXISTS:
        history.push(siteMap.LogInPage.path);
        break;

      case PHONE_VALIDATION_DIALOGS.UNVERIFIED_PHONE_BLOCKED:
        logoutWithRedirect(merchantData.merchantUrl);
        break;
      case USER_VALIDATION_DIALOGS.UNVERIFIED_ID_AND_PHONE_BLOCKED:
        logoutWithRedirect(siteMap.LogInPage.path);

        break;
      case PHONE_VALIDATION_DIALOGS.WRONG_ATTEMPT:
        popupService.setPopupStatus({ isOpen: false, dialogType: POPUP_TYPES.NOT_SELECTED });
        break;

      case COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG:
      default:
        popupService.setPopupStatus({ isOpen: false, dialogType: POPUP_TYPES.NOT_SELECTED });

        lastAction(...handlerParams);
        break;
      }

      // Always need to close popup manually, if happens redirect to another page, Because PopupWrapper
      // is on higher level then AppRouter, so it stays opened.
      popupService.setPopupStatus({ isOpen: false, dialogType: POPUP_TYPES.NOT_SELECTED });
    });

    if (popupCode === USER_VALIDATION_DIALOGS.USER_EXISTS) {
      popupEventEmitter.on(POPUP_EVENTS.CANCEL, () => {
        popupService.setPopupStatus({ isOpen: false, dialogType: POPUP_TYPES.NOT_SELECTED });
      });
    }

    return () => {
      popupEventEmitter.removeAllListeners(POPUP_EVENTS.AGREE);
      popupEventEmitter.removeAllListeners(POPUP_EVENTS.CANCEL);
    };
  }, [popupCode, lastAction, handlerParams]);

  return { openPopupWithParams };
};
