import { Box } from "@mui/material";
import { WarningBlock } from "@ui-kit";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { additionalContentCodes } from "@components/TopMenu/TopMenu.constants";
import { useMenuAdditionalContent, useMenuInfo } from "@store/menu";

import { BaseMobileHeader } from "./BaseMobileHeader";
import { HeaderWithStatusImg } from "./HeaderWithStatusImg";

export const MobileHeaderSwitcher: FC = () => {
  const [headerText, setHeaderText] = useState("");
  const [menuType, setMenuType] = useState("");
  const { t } = useTranslation();
  useMenuInfo((menuInfo) => setHeaderText(menuInfo.leftSide));
  useMenuAdditionalContent((additionalContentInfo) => setMenuType(additionalContentInfo.additionalContentCode));

  switch (menuType) {
  case additionalContentCodes.BASE_HEADER:
    return (
      <Box paddingTop="16px" flexGrow={1}>
        <BaseMobileHeader headerText={t(headerText)} />
      </Box>
    );
  case additionalContentCodes.HEADER_WITH_STATUS_IMG:
    return (
      <Box paddingTop="16px" flexGrow={1}>
        <HeaderWithStatusImg headerText={t(headerText)} />
      </Box>
    );
  case additionalContentCodes.WARNING_HEADER:
    return (
      <Box paddingTop="16px" flexGrow={1}>
        <WarningBlock warningText={t(headerText)} />
      </Box>
    );
  default:
    return null;
  }
};
