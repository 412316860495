import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import { Button, Typography } from "@ui-kit";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as AndroidButton } from "@assets/uiImages/android-button.svg";
import { ReactComponent as AppleButton } from "@assets/uiImages/apple-button.svg";
import PhonesMD from "@assets/uiImages/phones-md.png";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { ResponsiveImage } from "@components/ResponsiveImage";
import { LINK_TO_APP_ANDROID, LINK_TO_APP_APPLE, LINK_TO_APP_APPLE_DESKTOP, OSTypes } from "@constants/common";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";
import { getOSType } from "@utils/services/Mobile/Mobile.service";

interface IMobileDialog {
  isOpen: boolean;
  onClose: () => void;
}

const StyledDialog = styled(BasicDialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: "480px",
  },
  "& .MuiDialogContent-root": {
    maxWidth: "400px",
    overflow: "hidden",
    paddingBottom: "0px",
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-container.MuiDialog-scrollPaper": {
      alignItems: "flex-end",
    },
    "& .MuiPaper-root": {
      maxWidth: "400px",
      maxHeight: "100%",
    },
  },
}));

export const MobileDialog: FC<IMobileDialog> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [osType, setOsType] = useState<keyof typeof OSTypes>("PC");
  const [linkToMarket, setMarketLink] = useState("");
  const { t } = useTranslation();

  const handleGoToMarket = (linkUrl: string) => async () => {
    await logoutWithRedirect(linkUrl);
  };

  useEffect(() => {
    const os = getOSType();
    setOsType(os);

    if (os !== "PC") {
      setMarketLink(os === "Android" ? LINK_TO_APP_ANDROID : LINK_TO_APP_APPLE);
    }
  }, []);

  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
      icon={<ResponsiveImage src={PhonesMD} alt="app interface" />}
      description={
        <Box display="flex" flexDirection="column">
          <Box>
            <Typography variant="p1" fontWeight="bold">
              {t("dialogTitle.pleaseDownloadApp")}
            </Typography>
          </Box>
          <Box mr="16px" ml="16px" mt="8px">
            <Typography>
              {t(osType === "PC" ? "dialogContent.selectPlatform" : "dialogContent.downloadFromStore")}
            </Typography>
          </Box>
          {osType === OSTypes.PC ? (
            <Box
              display="flex"
              mt="40px"
              justifyContent="center"
              flexDirection={isMobile ? "column" : "row"}
            >
              <Box pr="16px" pl="16px" pb="8px">
                <AppleButton style={{ cursor: "pointer" }} onClick={handleGoToMarket(LINK_TO_APP_APPLE_DESKTOP)} />
              </Box>
              <Box pr="16px" pl="16px" pb="8px">
                <AndroidButton style={{ cursor: "pointer" }} onClick={handleGoToMarket(LINK_TO_APP_ANDROID)} />
              </Box>
            </Box>
          ) : (
            <Box mt="40px">
              <Button fullWidth style={{ cursor: "pointer" }} onClick={handleGoToMarket(linkToMarket)}>
                {t<string>("buttons.downloadApp")}
              </Button>
            </Box>
          )}
        </Box>
      }
    />
  );
};
