import { Grid } from "@mui/material";
import { Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { COMMON_REQUEST_DIALOGS } from "@appTypes/dialogs";
import { TextWithCountdownTimer } from "@components/TextWithCountdownTimer";
import { TextWithEmail } from "@components/TextWithEmail";
import { SUPPORT_EMAIL } from "@constants/common";
import { CHECK_SRS_DIALOG_TYPES } from "@utils/hooks/useCheckSRS/useCheckSRS";

import { employmentDialogTypes, EMPLOYMENT_DETAILS_DIALOGS } from "../EmploymentDetails/EmploymentDetails.types";

interface IDialogContentSwitcher {
  dialogType?: employmentDialogTypes;
  description?: string | JSX.Element;
  contentOptions: {
    redirectToMerchantWebsite(): Promise<void>;
  };
}

export const DialogContentSwitcher: FC<IDialogContentSwitcher> = ({ dialogType, description, contentOptions }) => {
  const { t } = useTranslation();

  switch (dialogType) {
  case EMPLOYMENT_DETAILS_DIALOGS.PRIVATE_SECTOR_NOT_VERIFIED:
  case EMPLOYMENT_DETAILS_DIALOGS.GOVERNMENT_SECTOR_NOT_VERIFIED:
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextWithEmail textKey={description as string} email={SUPPORT_EMAIL} />
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="p3">
            {t("dialogContent.whereToGo")}
          </Typography>
        </Grid>
      </Grid>
    );

  case EMPLOYMENT_DETAILS_DIALOGS.CONSENT:
    return (
      <Grid container spacing={4}>
        <Grid>
          <Typography variant="subtitle1" fontWeight="bold" width="100%">
            {t("employmentDetails.consentDialogTitle")}
          </Typography>
          <Typography
            align="center"
            variant="body2"
            fontSize={14}
            paddingY={1}
            paddingX={5}
          >
            {t("employmentDetails.consentDialogDescription")}
          </Typography>
        </Grid>
      </Grid>
    );

  case CHECK_SRS_DIALOG_TYPES.SRS_CHECK_HAVE_NOT_PASSED: {
    const redirectionTimer = { hours: 0, minutes: 0, seconds: 15 };

    return (
      <TextWithCountdownTimer
        countdownTimer={redirectionTimer}
        textKey={description as string}
        onTimeoutCallback={contentOptions.redirectToMerchantWebsite}
      />
    );
  }

  case COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG:
  default:
    return (
      <Typography variant="p3" textAlign="center" component="p">
        {description ? t(description as string) : ""}
      </Typography>
    );
  }
};
