import { Grid, GridTypeMap, styled } from "@mui/material";

type GridProps = GridTypeMap["props"];
interface IScrollableContainerProps extends GridProps {
  maxHeight: number;
}

const StyledScrollableContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "maxHeight",
})<IScrollableContainerProps>(({ maxHeight }) => ({
  maxHeight: `${maxHeight}px`,
  overflowY: "auto",
  scrollbarColor: "rgba(107, 114, 124, 0.24) white",
  scrollbarWidth: "thin",
  "::-webkit-scrollbar": {
    width: "5px",
  },
  "::-webkit-scrollbar-track": {
    boxShadow: "nset 0 0 6px grey",
    borderRadius: "5px",
  },
  "::-webkit-scrollbar-thumb": {
    background: `rgba(107, 114, 124, 0.24)`,
    opacity: "0.24",
    borderRadius: "15px",
    height: "2px",
  },
  "::-webkit-scrollbar-thumb:hover": {
    background: `rgba(107, 114, 124, 0.24)`,
    maxHeight: "10px",
  },
}));

export const ScrollableContainer: React.FC<IScrollableContainerProps> = ({ maxHeight, children, ...props }) => {
  return (
    <StyledScrollableContainer maxHeight={maxHeight} {...props}>
      {children}
    </StyledScrollableContainer>
  );
};
