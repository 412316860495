import { Grid } from "@mui/material";
import { COLORS, Typography } from "@ui-kit";
import { FC, memo } from "react";

interface IInfoLineProps {
  leftSide: string;
  rightSide?: string;
  mt?: string;
  mb?: string;
}

export const UserInfoLine: FC<IInfoLineProps> = memo(({ leftSide, rightSide = "", mt, mb }) => {
  return (
    <Grid
      item
      xs="auto"
      display="flex"
      flexWrap="wrap"
      minWidth="270px"
      justifyContent="space-between"
      alignItems="center"
      mt={mt}
      mb={mb}
    >
      <Typography variant="c1" mr="5px">
        {leftSide}
      </Typography>
      <Typography variant="p3" fontWeight="bold" color={COLORS.GREY_64}>
        {rightSide}
      </Typography>
    </Grid>
  );
});

UserInfoLine.displayName = "UserInfoLine";
