import throttle from "lodash.throttle";
import { MutableRefObject, useEffect, useRef, useState } from "react";

export const useContractResize = (): {
  pageWidth: number;
  containerRef: MutableRefObject<HTMLDivElement | undefined>;
} => {
  const containerRef = useRef<HTMLDivElement>();
  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    if (containerRef?.current?.offsetWidth) {
      setPageWidth((containerRef?.current && +containerRef?.current?.offsetWidth) || 0);

      const resizeListener = throttle(() => {
        setPageWidth((containerRef?.current && +containerRef?.current?.offsetWidth) || 0);
      }, 200);
      window.addEventListener("resize", resizeListener);

      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }
  }, [containerRef?.current?.offsetWidth]);

  return { pageWidth, containerRef };
};
