import { FC, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";

import { COMMON_REQUEST_DIALOGS } from "@appTypes/dialogs";
import { BasicDialog } from "@components/Dialogs/BasicDialog";

import { ILoginDialogs, LOGIN_DIALOGS } from "../login.types";
import { DialogContentsSwitcher } from "./DialogContentSwitcher";

export const UserLoginDialogs: FC<ILoginDialogs> = memo(
  ({ dialog, dialogType, dialogOptions, isDialogOpen, closeDialog, lastAction = noop, parameters = [] }) => {
    const { t } = useTranslation();

    const handleAgreeDialogClick = useCallback(() => {
      switch (dialogType) {
      // TODO: This status should be changed to TEMP_BLOCKED after CR - 30 Q99-2124
      case LOGIN_DIALOGS.USER_BLOCKED:
      case LOGIN_DIALOGS.USER_TEMP_BLOCKED:
        closeDialog();
        break;

      case COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG:
      default:
        closeDialog();
        lastAction(...parameters);
        break;
      }
    }, [dialogType, lastAction]);

    return isDialogOpen ? (
      <BasicDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onAgree={handleAgreeDialogClick}
        title={t(dialog?.title as string)}
        description={
          <DialogContentsSwitcher
            dialogType={dialogType}
            description={dialog?.description}
            dialogOptions={dialogOptions}
          />
        }
        icon={dialog?.icon}
        cancelButtonText={dialog?.cancelText ? t(dialog.cancelText as string) : ""}
        agreeButtonText={dialog?.agreeText ? t(dialog.agreeText as string) : ""}
      />
    ) : null;
  },
);

UserLoginDialogs.displayName = "UserLoginDialogs";
