import { Button, InputAdornment, styled, useTheme } from "@mui/material";
import { useState } from "react";

import { ReactComponent as QuestionMark } from "../../../assets/icons/question_mark_navy.svg";
import { COLORS } from "../../../constants/enums/COLORS";
import { IAbstractInputProps } from "../AbstractInput/AbstractInput";
import { BaseInput } from "../BaseInput";

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette?.secondary?.navy || COLORS.SECONDARY_NAVY,
  minWidth: "unset",
  width: "fit-content",
  padding: "0 10px",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

interface IActionInputProps extends IAbstractInputProps {
  actionButtonLabel: string;
  hintText?: string;
  handleActionClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ActionInput: React.FC<IActionInputProps> = ({
  label,
  labelId,
  value,
  hintText,
  actionButtonLabel,
  handleActionClick,
  ...props
}) => {
  const [isHintVisible, setHintVisible] = useState(false);
  const theme = useTheme();
  return (
    <>
      <BaseInput
        label={label}
        labelId={labelId}
        value={value}
        hintText={isHintVisible ? hintText : ""}
        endAdornment={
          <InputAdornment position={theme.direction === "ltr" ? "end" : "start"}>
            <ActionButton onClick={handleActionClick}>{actionButtonLabel}</ActionButton>
            <QuestionMark onMouseOver={() => setHintVisible(true)} onMouseOut={() => setHintVisible(false)} />
          </InputAdornment>
        }
        {...props}
      />
    </>
  );
};
