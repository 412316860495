import { FC, memo } from "react";

import { useFeatures } from "./Features.hooks";

interface IFeature {
  name: string;
  activeComponent: JSX.Element;
  inactiveComponent?: JSX.Element | null;
}

export const Feature: FC<IFeature> = memo(({ name, activeComponent, inactiveComponent = null }) => {
  const { isActiveFeature } = useFeatures();

  return isActiveFeature(name) ? <>{activeComponent}</> : <>{inactiveComponent}</>;
});

Feature.displayName = "Feature";
