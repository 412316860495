export const convertDigitsToArabic = (text: string | number = "", needConvert = true): string => {
  if (typeof text === "number") text = text.toString();
  return needConvert ? text.replace(/\d/g, (d) => "٠١٢٣٤٥٦٧٨٩"[d as unknown as number]) : text;
};

export const convertArabicToDigit = (text: string | number): string => {
  const arabicNumbersMap: Record<string, string> = {
    "٠": "0",
    "١": "1",
    "٢": "2",
    "٣": "3",
    "٤": "4",
    "٥": "5",
    "٦": "6",
    "٧": "7",
    "٨": "8",
    "٩": "9",
  };

  if (typeof text === "number") text = text.toString();
  return text.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => arabicNumbersMap[d]);
};

export const appendQueryStringWithCheck = (url: string, queryString: string): string => {
  return url.includes("?") ? `${url}&${queryString}` : `${url}?${queryString}`;
};
