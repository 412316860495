import { FormGroup, FormHelperText, Grid, styled, useTheme } from "@mui/material";
import React, { ChangeEvent, useState } from "react";

import SaudiArabiaFlag from "../../../assets/images/Saudi_Arabia_flag.png";
import { COLORS } from "../../../constants/enums/COLORS";
import { IAbstractInputProps } from "../AbstractInput/AbstractInput";
import { BaseInput } from "../BaseInput";
import { StyledInput, StyledLabel } from "../inputsStyles";

interface IPhoneNumberInputProps extends IAbstractInputProps {
  isAreaCodeDisabled?: boolean;
  flagImageSrc?: string;
  flagImageAltText?: string;
  areaCodeValue?: string;
  showAdditionalMessage?: boolean;
  onAreaCodeChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const AreaCodeInput = styled(StyledInput)(({ theme }) => ({
  ".Mui-disabled": {
    color: theme.palette.greyscale.grey64 || COLORS.GREY_64,
    "-webkit-text-fill-color": theme.palette.greyscale.grey64 || COLORS.GREY_64,
  },
  minWidth: "122px",
  width: "122px",
  direction: "ltr",
  fontWeight: theme.direction === "rtl" ? 600 : "bold",
}));

const AreaCodeWrapper = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.direction === "ltr" ? "8px" : "0 !important",
}));

export const PhoneNumberInput: React.FC<IPhoneNumberInputProps> = ({
  label,
  labelId,
  isAreaCodeDisabled = true,
  flagImageSrc,
  flagImageAltText,
  onAreaCodeChange,
  areaCodeValue,
  showAdditionalMessage = true,
  ...props
}) => {
  const [isPhoneInputFocused, setIsPhoneInputFocused] = useState(false);
  const theme = useTheme();

  return (
    <>
      <StyledLabel
        htmlFor={labelId}
        disabled={props.disabled}
        sx={isPhoneInputFocused ? { color: COLORS.SECONDARY_NAVY } : null}
      >
        {label}
      </StyledLabel>
      <FormGroup>
        <Grid
          flexWrap="nowrap"
          container
          spacing={1}
          sx={{
            width: "100%",
            minWidth: "327px",
            maxWidth: "376px",
            flexDirection: theme.direction === "rtl" ? "row-reverse" : "row",
          }}
        >
          <AreaCodeWrapper item>
            <AreaCodeInput
              startAdornment={
                <img
                  width={"24px"}
                  src={flagImageSrc || SaudiArabiaFlag}
                  style={{ marginRight: "8px" }}
                  alt={flagImageAltText || "Saudi Arabia flag image"}
                />
              }
              value={areaCodeValue || "+966"}
              aria-labelledby={labelId}
              disabled={isAreaCodeDisabled}
              onChange={onAreaCodeChange}
            />
          </AreaCodeWrapper>
          <Grid item flexGrow={1}>
            <BaseInput
              minWidth="197px"
              maxWidth="246px"
              aria-labelledby={labelId}
              labelId={labelId}
              onFocus={() => setIsPhoneInputFocused(true)}
              onBlur={() => setIsPhoneInputFocused(false)}
              showAdditionalMessage={false}
              allowNumbersOnly={true}
              {...props}
            />
          </Grid>
        </Grid>
        {showAdditionalMessage && (
          <FormHelperText
            sx={{
              fontWeight: theme.direction === "rtl" ? 600 : "bold",
              color: props.errorText ? COLORS.DARK_NEGATIVE : "#6a6a6a",
              display: "flex",
            }}
          >
            {props.errorText || props.hintText || ` `}
          </FormHelperText>
        )}
      </FormGroup>
    </>
  );
};
