import { useEffect } from "react";

import { menuService } from "./menu";
import { IMenuAdditionalInfo, IMenuInfo } from "./menu.types";

export const useMenuInfo = (setMenuInfo: (menuInfo: IMenuInfo) => void): void => {
  useEffect(() => {
    const menuInfoSubscription = menuService.getMenuData().subscribe((menuData: IMenuInfo) => setMenuInfo(menuData));

    return () => menuInfoSubscription.unsubscribe();
  }, []);
};

export const useMenuAdditionalContent = (
  setAdditionalContent: (additionalContent: IMenuAdditionalInfo) => void,
): void => {
  useEffect(() => {
    const additionalInfoSubscription = menuService
      .getAdditionalContentInfo()
      .subscribe((additionalInfo: IMenuAdditionalInfo) => setAdditionalContent(additionalInfo));

    return () => additionalInfoSubscription.unsubscribe();
  }, []);
};
