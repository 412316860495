import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { styled } from "@mui/material";
import { useRef, useState } from "react";

import { ReactComponent as Checkmark } from "../../assets/icons/checkmark_green.svg";
import { ReactComponent as Chevron } from "../../assets/icons/dropdown_chevron.svg";
import { COLORS } from "../../constants/enums/COLORS";
import useOnOutsideClick from "../../hooks/useOnClickOutside";
import { StyledLabel } from "../Inputs/inputsStyles";
import { Typography } from "../Typography";

interface IDropdownElement {
  label: string;
  key?: string;
}

interface IDropdownProps {
  optionsArray: Array<IDropdownElement>;
  onClick: (value: string) => void;
  value: string;
  placeholder?: string;
  icon?: ReactJSXElement;
  chosenValueIcon?: ReactJSXElement;
  label?: string;
  labelId?: string;
}

interface IDropdownSelectProps {
  isOpen: boolean;
}

const DropdownContainer = styled("div")`
  overflow: visible;
  position: relative;
`;

const DropdownSelect = styled("div", { shouldForwardProp: (prop) => prop !== "isOpen" })<IDropdownSelectProps>(
  ({ theme, isOpen }) => ({
    boxSizing: "border-box",
    backgroundColor: theme.palette?.greyscale?.grey04 || COLORS.GREY_04,
    color: theme.palette?.greyscale?.grey100 || COLORS.GREY_100,
    minHeight: "56px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "227px",
    padding: "8px 16px",
    borderRadius: isOpen ? "8px 8px 0 0" : "8px",
    fontSize: "1.4rem",
    fontWeight: 700,
    cursor: "pointer",
    transitionDuration: "0.3s",
    transitionProperty: "border-radius transform",
    "& .chevronIcon": {
      transitionDuration: "0.3s",
    },
    ...(isOpen && {
      "& .chevronIcon": {
        transform: "rotate(180deg)",
        transitionDuration: "0.3s",
      },
    }),
  }),
);

const ValueIconContainer = styled("div")(({ theme }) => ({
  stroke: theme.palette?.greyscale?.grey04,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  svg: {
    marginRight: "12px",
    width: "24px",
  },
}));

const ListContainer = styled("ul")(({ theme }) => ({
  color: theme.palette?.secondary?.main,
  boxSizing: "border-box",
  backgroundColor: theme.palette?.greyscale?.grey04 || COLORS.GREY_04,
  listStyle: "none",
  margin: "0",
  borderBottomRightRadius: "8px",
  borderBottomLeftRadius: "8px",
  position: "absolute",
  top: "100%",
  left: "0",
  width: "100%",
  overflow: "hidden",
  display: "grid",
  gridGap: "2px",
  padding: "2px",
  zIndex: 9999,
  "@keyframes fadein": {
    from: { opacity: 0, transform: "translateY(-50px)" },
    to: { opacity: 1, transform: "unset" },
  },
  animation: "fadein 0.3s",
}));

const ListItem = styled("li")(({ theme }) => ({
  color: theme.palette?.greyscale?.grey100 || COLORS.GREY_100,
  backgroundColor: theme.palette?.greyscale?.white || COLORS.WHITE,
  height: "56px",
  display: "flex",
  justifyContent: "space-between",
  cursor: "pointer",
  boxSizing: "border-box",
  alignItems: "center",
  fontSize: "1.4rem",
  padding: "16px",
  "&:hover": {
    backgroundColor: theme.palette?.greyscale?.grey04 || COLORS.GREY_04,
  },
  "&:last-of-type": {
    borderRadius: "0 0 8px 8px",
  },
  svg: {
    width: "16px",
  },
}));

export const Dropdown: React.FC<IDropdownProps> = ({
  placeholder,
  optionsArray,
  onClick,
  value,
  label,
  labelId,
  icon,
  chosenValueIcon,
}) => {
  const ref = useRef(null);
  const [isOpen, setDropdownIsOpen] = useState(false);
  useOnOutsideClick(ref, () => setDropdownIsOpen(false));

  return (
    <DropdownContainer ref={ref}>
      {label && labelId && (
        <StyledLabel shrink={true} disableAnimation={true} htmlFor={labelId}>
          {label}
        </StyledLabel>
      )}
      <DropdownSelect onClick={() => setDropdownIsOpen(!isOpen)} isOpen={isOpen}>
        <ValueIconContainer>
          {!!icon && icon}
          {value}
          {!value && (
            <Typography variant="p3" fontWeight="bold" color="#C2C2C2">
              {placeholder}
            </Typography>
          )}
        </ValueIconContainer>
        <Chevron className={"chevronIcon"} />
      </DropdownSelect>
      {isOpen && optionsArray?.length && (
        <ListContainer>
          {optionsArray.map(({ label, key }) => (
            <ListItem
              key={key || label}
              onClick={() => {
                setDropdownIsOpen(false);
                onClick(key || "");
              }}
            >
              {label}
              {label === value && (chosenValueIcon ? chosenValueIcon : <Checkmark />)}
            </ListItem>
          ))}
        </ListContainer>
      )}
    </DropdownContainer>
  );
};
