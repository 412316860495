export enum ORDER_STATUSES {
  PLACED = "PLACED",
  NOT_CREATED_BY_GOSI = "NOT_CREATED_BY_GOSI",
  NOT_CREATED_BY_SYS = "NOT_CREATED_BY_SYS",
  REJECTED_UNSIGNED_CONTRACT = "REJECTED_UNSIGNED_CONTRACT",
  CREATED = "CREATED",
  REJECTED_BY_PROVENIR = "REJECTED_BY_PROVENIR",
  OFFERED = "OFFERED",
  DECLINED_ON_WEB = "DECLINED_ON_WEB",
  ABANDONED_BY_USER = "ABANDONED_BY_USER",
  WAITING_CONFIRMATION_CALL = "WAITING_CONFIRMATION_CALL",
  CANCELED_BY_LMS = "CANCELED_BY_LMS",
  WAITING_FOR_SIGNING = "WAITING_FOR_SIGNING",
  DECLINED = "DECLINED",
  EXPIRED_NO_SIGNED_CONTRACT = "EXPIRED_NO_SIGNED_CONTRACT",
  SIGNED = "SIGNED",
  DISBURSED = "DISBURSED",
  DEFAULTED = "DEFAULTED",
  LATE = "LATE",
  COMPLETED = "COMPLETED",
  CANCELLED_FROM_MERCHANT = "CANCELLED_FROM_MERCHANT",
  REJECTED_BY_PHONE_CALL = "REJECTED_BY_PHONE_CALL",
}
