import { Box, Grid, useMediaQuery } from "@mui/material";
import { theme, Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import BG_IMG_DESKTOP from "@assets/uiImages/404_bg_desktop.png";
import BG_IMG_MOBILE from "@assets/uiImages/404_bg_mobile.png";
import { TopMenu } from "@components/TopMenu";
import { PreErrorText, ContentWrapper, ImgContainer } from "@pages/Error404Page/styles";

export const Error404Page: FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <TopMenu />
      <ContentWrapper container>
        <Grid
          item
          sm={12}
          md={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box>
            {isMobile ? (
              <Typography
                variant="h4"
                component="p"
                textAlign={"center"}
                fontWeight={"bold"}
                margin={"24px 0 16px"}
              >
                {t("error404Header")}
              </Typography>
            ) : (
              <PreErrorText>{t("error404Header")}</PreErrorText>
            )}
            <Typography
              component="p"
              variant={isMobile ? "p2" : "h4"}
              fontWeight={isMobile ? "normal" : "bold"}
              textAlign={"center"}
              maxWidth={isMobile ? "327px" : "477px"}
            >
              {t("error404")}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          display={"flex"}
        >
          <ImgContainer>
            <img alt="404 error background image" src={isMobile ? BG_IMG_MOBILE : BG_IMG_DESKTOP} />
          </ImgContainer>
        </Grid>
      </ContentWrapper>
    </>
  );
};
