import { Box, Grid } from "@mui/material";
import { Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { COMMON_REQUEST_DIALOGS } from "@appTypes/dialogs";

import { IDialogOptions, PERSONAL_DATA_DIALOGS, setPasswordDialogs } from "../SetUserPassword.types";
import { UserInfoLine } from "./UserInfoLine";

interface IDialogContentSwitcher {
  dialogType?: setPasswordDialogs;
  description?: string | JSX.Element;
  dialogOptions?: IDialogOptions;
}

export const DialogContentsSwitcher: FC<IDialogContentSwitcher> = ({
  dialogType,
  description = "",
  dialogOptions = {},
}) => {
  const { t } = useTranslation();

  switch (dialogType) {
  case PERSONAL_DATA_DIALOGS.UNVERIFIED_DATE_OF_BIRTH:
    return (
      <Grid container flexDirection="column" alignItems="center">
        <Box>
          <UserInfoLine leftSide={`${t("labels.email")}:`} rightSide={dialogOptions?.email} mb="10px" />
          <UserInfoLine leftSide={`${t("labels.mobile")}:`} rightSide={dialogOptions?.phoneNumber} mb="10px" />
          <UserInfoLine leftSide={`${t("labels.nationalIdOrIQAMA")}:`} rightSide={dialogOptions?.nationalId} />
        </Box>
      </Grid>
    );

  case COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG:
  default:
    return (
      <Typography variant="p3" textAlign="center" component="p">
        {description ? t(description as string) : ""}
      </Typography>
    );
  }
};
