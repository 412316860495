import { COMMON_REQUEST_DIALOGS } from "@appTypes/dialogs";

export enum KYC_REQUEST_STATUSES {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  TEMP_BLOCKED = "TEMP_BLOCKED",
}

export enum KYC_GENERATION_DIALOGS {
  USER_TEMP_BLOCKED = "USER_TEMP_BLOCKED",
}

export enum KYC_VERIFICATION_DIALOGS {
  OTP_FAILED = "OTP_FAILED",
  USER_TEMP_BLOCKED = "USER_TEMP_BLOCKED",
}

export type kycDialogs = KYC_GENERATION_DIALOGS | KYC_VERIFICATION_DIALOGS | COMMON_REQUEST_DIALOGS;
