import { BehaviorSubject, Observable } from "rxjs";

import { defaultAdditionalContent, defaultMenuData } from "./menu.constants";
import { IMenuAdditionalInfo, IMenuInfo } from "./menu.types";

const menu$ = new BehaviorSubject<IMenuInfo>(defaultMenuData);
const additionalContent$ = new BehaviorSubject<IMenuAdditionalInfo>(defaultAdditionalContent);

export const menuService = {
  setMenuData: (menu: Partial<IMenuInfo>): void => menu$.next({ ...menu$.getValue(), ...menu }),
  getMenuData: (): Observable<IMenuInfo> => menu$.asObservable(),
  getMenuDataValue: (): IMenuInfo => menu$.value,
  getAdditionalContentInfo: (): Observable<IMenuAdditionalInfo> => additionalContent$.asObservable(),
  setAdditionalContentInfo: (additionalContent: Partial<IMenuAdditionalInfo>): void =>
    additionalContent$.next({ ...additionalContent$.getValue(), ...additionalContent }),
};
