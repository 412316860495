import { useEffect, useState } from "react";

import { getContractRequest } from "../network/getContract";

interface IContractResponse {
  contract: ArrayBuffer | undefined;
  fileName: string;
  isContractLoadingError: boolean;
}

export const useContract = (contractKey: string): IContractResponse => {
  const [contract, setContract] = useState<ArrayBuffer>();
  const [fileName, setFileName] = useState("");
  const [isContractLoadingError, setLoadingErrorFlag] = useState(true);

  useEffect(() => {
    if (contractKey) {
      const getContract = async () => {
        try {
          const contractData = await getContractRequest(contractKey);
          const contractFileName = contractData.headers["content-disposition"].split("filename=")[1].split(".pdf")[0];

          setLoadingErrorFlag(false);
          setFileName(contractFileName);
          setContract(contractData.data);
        } catch (e) {
          setLoadingErrorFlag(true);
        }
      };

      getContract();
    }
  }, [contractKey]);

  return { contract, fileName, isContractLoadingError };
};
