export const CLIENT_FRONTEND = "frontend";
export const CLIENT_CONNECT = "connect-api";

export const BASE_URL =
  process.env.REACT_APP_WITH_MOCK_SERVER === "true" ? "http://localhost:3002" : process.env.REACT_APP_BASE_URL;

export const KEYCLOAK_SERVICE_URL = "/api/v1/keycloak";
export const ORDER_SERVICE_URL = "/api/v1/order";
export const STORAGE_SERVICE_URL = "/api/v1/storage";
export const CONNECT_SERVICE_URL = "/api/v1/connect";

export const unauthorizedRequests = [
  `${KEYCLOAK_SERVICE_URL}/password/reset/submit`,
  `${KEYCLOAK_SERVICE_URL}/password/reset/check`,
  `${KEYCLOAK_SERVICE_URL}/service/token`,
  `${CONNECT_SERVICE_URL}/customer/token/refreshment`,
];

export const UNPROTECTED_ENDPOINTS = [
  "/api/v1/connect/otp/info",
  "/api/v1/connect/otp/verify",
  "/api/v1/connect/otp/verify",
  "/api/v1/connect/otp/kyc/verify",
];

export const WITHOUT_TOKEN_ENDPOINTS = ["/api/v1/connect/customer/login"];
